import type { LucideIcon } from 'lucide-vue-next'
import type { IItemRewardTier } from '@/types/itemReward'

export interface INotification<T = Date> {
	id: number
	consumed: boolean
	created_at: T
	new?: boolean
	action?: () => void
	data?: any
	hide?: boolean
	iconColor?: string
	titleColor?: string
	icon?: LucideIcon
	type: ENotificationType
	key?: string
	delay?: number
}

export interface INotificationXpGain<T = Date> extends INotification<T> {
	xp_gain: number
}

export interface INotificationContainer {
	notifs: INotification[]
	newNotifs?: number
	newRewards?: number
}

export interface IBundleNotification extends INotification {
	expires_at: Date
	reward: number
	event_code: string
}

export interface IAppEventNotification extends INotification {
	expires_at: Date
}

export interface IReportNotification extends INotificationXpGain {
	data: Record<'count', number>
}

export interface IAchievementNotification extends INotificationXpGain {
	item_reward_tier: IItemRewardTier
	key: string
}

export interface IDailyNotification extends INotificationXpGain {
	data: Record<'streak', number>
}

export interface ILevelUpNotification extends INotification {
	data: Record<'level', number>
}

export enum ENotificationType {
	Bundle = 'bundle',
	Report = 'report',
	Achievement = 'achievement',
	Daily = 'daily',
	LevelUp = 'level_up',
	App = 'app',
	AppError = 'app_error',
	AppEvent = 'app_event',
	DailyLoss = 'daily_loss',
}
