import type { ApiRequest, RequestRoutes } from '@/request/requestRouter'
import { RequestAction } from '@/request/requestRouter'
import type { IAchievement, IProfile } from '@/types'

const getUserProfile: ApiRequest<IProfile> = {
	action: RequestAction.GetUserProfile,
	endpoint: '{{userId}}/profile',
	config: {
		method: 'GET',
	},
	afterRequest: async (response: Response) => await response.json(),
}

const getProfileAchievements: ApiRequest<IAchievement[]> = {
	action: RequestAction.GetProfileAchievements,
	endpoint: '{{userId}}/achievements',
	config: {
		method: 'GET',
	},
	afterRequest: async (response: Response) => await response.json(),
}

export default {
	prefix: '/api/public',
	middlewares: [],
	queries: [getUserProfile, getProfileAchievements],
} as RequestRoutes
