import { defineStore } from 'pinia'
import { inject, ref } from 'vue'
import { addDays } from 'date-fns'
import { useSettingStore } from './settingStore'
import { usePlayerStore } from '@/stores/playerStore'
import { i18n } from '@/i18n'
import Tools from '@/utils/tools'
import { ENotificationType, type IUser, type IUserLoginAPI, type IUserRegistration, type IUserUpdateAPI } from '@/types'
import { RequestAction, type RequestRouter } from '@/request/requestRouter'
import { useNotificationStore } from '@/stores/notificationStore'
import { IUserFactory } from '@/types/factory'
import FapFapError from '@/errors/FapFapError'

export const useAuthStore = defineStore('authStore', () => {
	const request = inject<RequestRouter>('request')

	const playerStore = usePlayerStore()
	const settingStore = useSettingStore()
	const notifStore = useNotificationStore()

	const { t } = i18n.global

	const auth = ref<boolean>(false)
	const user = ref<IUser>(IUserFactory())

	const logoutCallbacks = ref<Array<() => void>>([])

	const addLogoutCallback = (cb: () => void): void => {
		logoutCallbacks.value.push(cb)
	}

	const authCookie = Tools.getCookie('auth')
	auth.value = authCookie === 'true'

	const reloadPage = () => {
		window.location.reload()
		setTimeout(() => {
			window.location.href = '/'
		}, 0)
	}

	const refreshUser = () => {
		if (auth.value) {
			request?.exec(RequestAction.GetUserSettings)
				.then((_user: IUser | null) => {
					if (_user) {
						user.value = _user
						// force premium
						user.value.premium.active = true
					}
					else {
						console.error('No user found:', _user)
					}
				})
				.then(() => notifStore.loadNotifications())
				.then(() => playerStore.setPlayer())
				.catch((error) => {
					console.error('Error during refreshUser chain:', error)
				})
		}
	}

	refreshUser()

	const updateUser = async (data: IUserUpdateAPI | Partial<IUserUpdateAPI>): Promise<void> => {
		if (data.user) {
			data.user.lang = settingStore.getLocale().code
		}

		const res = await request?.exec(RequestAction.UpdateUser, {
			body: data,
		})
		if (res && res.ok) {
			notifStore.push(ENotificationType.App, {
				key: 'notification.dashboard',
				delay: 4000,
			})
			if (user.value && 'user' in data) {
				if (typeof data.user?.username === 'string' && data.user.username.length) {
					user.value.username = data.user.username.length ? data.user.username : user.value.username
				}
				if (typeof data.user?.keep_updated === 'boolean') {
					user.value.keep_updated = data.user.keep_updated
				}
				if (typeof data.user?.lang === 'string' && data.user.lang.length) {
					user.value.lang = data.user.lang
				}
			}
		}
		else if (res) {
			const error = await res.json()
			throw FapFapError.auto(error.detail.code)
		}
	}

	const register = async (data: IUserRegistration): Promise<void> => {
		const res = await request?.exec(RequestAction.CreateUser, {
			body: data,
		})
		if (res && !res.ok) {
			const error = await res.json()
			throw FapFapError.auto(error?.code ?? error.detail.code)
		}
	}

	const login = async (data: IUserLoginAPI): Promise<void> => {
		const expirationDate = addDays(new Date(), 30)

		const res = await request?.exec(RequestAction.Login, {
			body: data,
		})

		if (res && res.ok) {
			const _user = await request?.exec(RequestAction.GetUserSettings)
			await playerStore.setPlayer()
			if (_user) {
				user.value = _user
				// force premium
				user.value.premium.active = true
				auth.value = true
				Tools.setCookie('auth', 'true', expirationDate, `.${import.meta.env.VITE_DOMAIN}`, '/')
				reloadPage()
			}
		}
		else if (res) {
			const error = await res.json()
			throw FapFapError.auto(error.detail.code)
		}
	}

	const loginGoogle = async (): Promise<void> => {
		const expirationDate = addDays(new Date(), 30)

		const _user = await request?.exec(RequestAction.GetUserSettings)
		await playerStore.setPlayer()
		if (_user) {
			user.value = _user
			auth.value = true
			// force premium
			user.value.premium.active = true
			Tools.setCookie('auth', 'true', expirationDate, `.${import.meta.env.VITE_DOMAIN}`, '/')
			reloadPage()
		}
	}

	const logout = async (): Promise<boolean> => {
		const res = await request?.exec(RequestAction.Logout)

		if (res && res.ok) {
			auth.value = false
			user.value.premium.active = false
			reloadPage()
			return true
		}
		else {
			return false
		}
	}

	const isAuth = (): boolean => {
		const authCookie = Tools.getCookie('auth')
		auth.value = authCookie === 'true'
		return auth.value
	}

	return { isAuth, user, auth, loginGoogle, login, logout, register, updateUser, refreshUser, addLogoutCallback }
})
