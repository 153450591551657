import { RequestAction } from '@/request/requestRouter'
import type { ApiRequest, RequestBodyTypes, RequestConfig, RequestRoutes } from '@/request/requestRouter'
import checkNotificationMiddleware from '@/request/middlewares/notification'

const addImageToFavorites: ApiRequest = {
	action: RequestAction.AddImageToFavorites,
	endpoint: 'favorite',
	config: {
		method: 'POST',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
		},
	},
}

const deleteImageFromFavorites: ApiRequest = {
	action: RequestAction.DeleteImageFromFavorites,
	endpoint: 'favorite',
	config: {
		method: 'DELETE',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
		},
	},
}

const deleteImagesFromFavorites: ApiRequest<Response, RequestAction.DeleteImagesFromFavorites> = {
	action: RequestAction.DeleteImagesFromFavorites,
	endpoint: 'favorites',
	config: {
		method: 'DELETE',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
		},
	},
	beforeRequest: (config: RequestConfig<RequestBodyTypes[RequestAction.DeleteImagesFromFavorites]>) => {
		const body = config.body

		if (body && 'uuid' in body && Array.isArray(body.uuid)) {
			config.body = {
				images: body.uuid.map(val => ({ uuid: val })),
			}
		}

		return config
	},
}

const reportImage: ApiRequest = {
	action: RequestAction.ReportImage,
	endpoint: 'report',
	config: {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
	},
}

const setReaction: ApiRequest = {
	action: RequestAction.SetReaction,
	endpoint: 'react/{{reaction}}',
	config: {
		method: 'PUT',
	},
}

export default {
	prefix: '/api/action',
	middlewares: [checkNotificationMiddleware],
	queries: [
		addImageToFavorites,
		deleteImageFromFavorites,
		deleteImagesFromFavorites,
		reportImage,
		setReaction,
	],
} as RequestRoutes
