import type { LucideIcon } from 'lucide-vue-next'
import { TierAIcon, TierBIcon, TierCIcon, TierSIcon } from '@/icons/CustomLucideIcons'
import type { IItemRewardTier } from '@/types'

export const CItemTierIcon: Record<IItemRewardTier, LucideIcon> = {
	S: TierSIcon,
	A: TierAIcon,
	B: TierBIcon,
	C: TierCIcon,
}

export const CItemTierColor: Record<IItemRewardTier, string> = {
	S: 'text-yellow-500',
	A: 'text-purple-500',
	B: 'text-blue-500',
	C: 'text-gray-500',
}

export const CItemTierUpgrade: Record<IItemRewardTier, IItemRewardTier | null> = {
	S: null,
	A: 'S',
	B: 'A',
	C: 'B',
}

export const CItemTierDowngrade: Record<IItemRewardTier, IItemRewardTier | null> = {
	S: 'A',
	A: 'B',
	B: 'C',
	C: null,
}
