import router from '@/router'
import { useAuthStore } from '@/stores/authStore'
import { useUserModalStore } from '@/stores/userModalStore'
import { EUserModalType } from '@/types'

export interface IUseFavorites {
	routeToFavorites: () => void
}

export function useFavorites(): IUseFavorites {
	const authStore = useAuthStore()
	const userModalStore = useUserModalStore()

	function routeToFavorites() {
		if (!authStore.isAuth()) {
			userModalStore.displayUserModal(EUserModalType.LockedFeature, { messageI18n: 'locked_feature.message_route_favorite', doNotShow: false })
		}
		else {
			router.push('/favorites')
		}
	}
	return { routeToFavorites }
}
