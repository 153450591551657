<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { defineAsyncComponent, inject, nextTick, onBeforeUnmount, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useSettingStore } from '@/stores/settingStore'
import type { IItemRewardTier } from '@/types'
import { EImageGalleryType } from '@/types'
import { useEventGalleryStore } from '@/stores/eventGalleryStore'
import { RequestAction, type RequestRouter } from '@/request/requestRouter'
import { CItemTierColor, CItemTierIcon } from '@/constants'
import { useAudioStore } from '@/stores/audioStore'
import { usePlayerStore } from '@/stores/playerStore'
import { useAuthStore } from '@/stores/authStore'

const props = defineProps<Props>()

const request = inject<RequestRouter>('request')

const { t } = useI18n()
const router = useRouter()
const authStore = useAuthStore()
const ZoomedImage = defineAsyncComponent(() => import('@/components/ZoomedImage.vue'))
const Image = defineAsyncComponent(() => import('@/components/Image.vue'))
const CardReveal = defineAsyncComponent(() => import('@/components/CardReveal.vue'))
const SpinnerHeart = defineAsyncComponent(() => import('@/components/SpinnerHeart.vue'))

interface Props {
	uuid: string
}

interface FoundReward {
	tier: IItemRewardTier
	amount: number
}

const imageUuid = ref<string>('')
const totalImageLoaded = ref<number>(0)
const revealAll = ref<boolean>(false)
const foundReward = ref<FoundReward>({
	tier: 'C',
	amount: 0,
})
const imageContainingReward = ref<string>('')
const settingStore = useSettingStore()
const bundleStore = useEventGalleryStore()
const playerStore = usePlayerStore()

const { images = ref([]), index: imageIndex } = storeToRefs(bundleStore)
const { player } = storeToRefs(playerStore)
const { galleryType, imageFullScreen, galleryEventScroll } = storeToRefs(settingStore)

galleryType.value = EImageGalleryType.Event

watch(
	() => imageFullScreen.value,
	(newVal) => {
		if (newVal) {
			imageUuid.value = images.value[imageIndex.value].uuid
		}
	},
)

function zoomImage(index: number) {
	bundleStore.setZoomedImage(index)
	imageFullScreen.value = true
}

function popstate() {
	imageFullScreen.value = false
}

window.addEventListener('popstate', popstate)

onBeforeUnmount(() => {
	window.removeEventListener('popstate', popstate)
	galleryEventScroll.value = window.scrollY
})

watch(() => props.uuid, async () => {
	if (props.uuid !== 'visitor') {
		try {
			await bundleStore.load(props.uuid)
		}
		catch (err) {
			console.error(err)
			router.replace('/404')
			bundleStore.clear()
			return
		}
	}
	await nextTick()
	if (!images.value || !images.value.length) {
		router.replace('/404')
		bundleStore.clear()
	}
}, { immediate: true })

function imageLoaded() {
	totalImageLoaded.value++
}

function checkForReward(containsToken: boolean, imageUuid: string) {
	if (containsToken && authStore.isAuth()) {
		request?.exec(RequestAction.GetEventTokenReward, {
			routeParams: {
				imageUuid,
				eventUuid: props.uuid,
			},
		})
			.then(async (res) => {
				const data = await res.json()
				if (res && res.ok) {
					foundReward.value = data
					player.value.tokens[foundReward.value.tier] += foundReward.value.amount
					imageContainingReward.value = imageUuid
					revealAll.value = true
					useAudioStore().play('reward_event')
				}
			})
			.catch()
	}
}
</script>

<template>
	<SpinnerHeart v-if="!images || totalImageLoaded < images.length" class="!fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`" />

	<div class="fixed h-[50px] top-0 left-0 w-screen z-[13] bg-gray-900 flex justify-between items-center sm:px-6 px-2">
		<div v-if="!foundReward.amount && authStore.isAuth()">
			{{ t('event.hidden_reward') }}
		</div>
		<div v-else-if="authStore.isAuth()" class="flex gap-1 items-center anim-reward">
			{{ t('event.reward') }}
			<component :is="CItemTierIcon[foundReward.tier]" :class="CItemTierColor[foundReward.tier]" :size="24" />x {{ foundReward.amount }} !
		</div>
		<Button :label="t('event.exit')" class="sm-button" outlined @click="router.push('/')" />
	</div>
	<div v-if="images && images.length" class="mt-[55px] mb-[5px] flex w-full justify-center h-[calc(100vh-60px)]">
		<div v-show="totalImageLoaded >= images.length" style="-webkit-overflow-scrolling: touch;" class="w-full flex flex-wrap justify-center relative gap-1">
			<template v-for="(image, index) in images" :key="image.uuid">
				<div class="flex justify-center w-full h-auto sm:w-[250px] sm:h-[360px] lg:w-[315px] lg:h-[454px] xl:w-[420px] xl:h-[606px]">
					<CardReveal :reveal="revealAll" @click="checkForReward(image.token ?? false, image.uuid)">
						<template #default>
							<Image :interactable="revealAll" :data="image" :index="image.uuid" :class="imageContainingReward === image.uuid ? 'border-2 border-yellow-400 border-solid' : ''" @on-image-loaded="imageLoaded" @on-image-zoom="zoomImage(index)" />
						</template>
					</CardReveal>
				</div>
			</template>
		</div>
	</div>
	<ZoomedImage v-if="imageFullScreen" :uuid="imageUuid" :previous-route="`/event/${uuid}`" />
</template>

<style scoped>
@keyframes anim {
	0%, 100% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.3);
	}
}

.anim-reward {
  animation: anim 0.3s linear 0s 1 forwards;
}
</style>
