export default class FapFapError extends Error {
	public code: string

	constructor(message: string, code: string) {
		super(message)
		this.code = code
		this.name = 'FapFapError'
	}

	static waitForEvent(): WaitForEventError {
		return new WaitForEventError()
	}

	static invalidRewardTokenCode(): InvalidRewardTokenCodeError {
		return new InvalidRewardTokenCodeError()
	}

	static duplicateFavorite(): DuplicateFavoriteError {
		return new DuplicateFavoriteError()
	}

	static favoritesLimitExceeded(): FavoritesLimitExceededError {
		return new FavoritesLimitExceededError()
	}

	static imageNotFound(): ImageNotFound {
		return new ImageNotFound()
	}

	static unauthorizedResource(): UnauthorizedResource {
		return new UnauthorizedResource()
	}

	static duplicatePreset(): DuplicatePresetError {
		return new DuplicatePresetError()
	}

	static duplicateUsername(): DuplicateUsernameError {
		return new DuplicateUsernameError()
	}

	static usernameFormat(): UsernameFormatError {
		return new UsernameFormatError()
	}

	static usernameLength(): UsernameLengthError {
		return new UsernameLengthError()
	}

	static passwordLength(): PasswordLengthError {
		return new PasswordLengthError()
	}

	static duplicateEmail(): DuplicateEmailError {
		return new DuplicateEmailError()
	}

	static emailFormat(): EmailFormatError {
		return new EmailFormatError()
	}

	static emailLength(): EmailLengthError {
		return new EmailLengthError()
	}

	static requiredUsername(): RequiredUsernameError {
		return new RequiredUsernameError()
	}

	static requiredEmail(): RequiredEmailError {
		return new RequiredEmailError()
	}

	static requiredPassword(): RequiredPasswordError {
		return new RequiredPasswordError()
	}

	static badCredentials(): BadCredentialsError {
		return new BadCredentialsError()
	}

	static userNotActive(): UserNotActiveError {
		return new UserNotActiveError()
	}

	static maxDeviceReach(): UserMaxDeviceError {
		return new UserMaxDeviceError()
	}

	static unauthorizedDevice(): UserUnauthorizedDeviceError {
		return new UserUnauthorizedDeviceError()
	}

	static NoDevice(): UserNoDeviceError {
		return new UserNoDeviceError()
	}

	static deviceNotFound(): UserDeviceNotFoundError {
		return new UserDeviceNotFoundError ()
	}

	static rateLimited(): RateLimitedError {
		return new RateLimitedError ()
	}

	static auto(code: string): FapFapError | null {
		const errorMap: Record<string, () => FapFapError | null> = {
			wait_for_event: FapFapError.waitForEvent,
			invalid_reward_token_code: FapFapError.invalidRewardTokenCode,
			duplicate_favorite: FapFapError.duplicateFavorite,
			image_not_found: FapFapError.imageNotFound,
			unauthorized_resource: FapFapError.unauthorizedResource,
			favorites_limit_exceeded: FapFapError.favoritesLimitExceeded,
			duplicate_filter_set: FapFapError.duplicatePreset,
			bad_length_username: FapFapError.usernameLength,
			bad_format_username: FapFapError.usernameFormat,
			bad_length_pwd: FapFapError.passwordLength,
			duplicate_username: FapFapError.duplicateUsername,
			duplicate_email: FapFapError.duplicateEmail,
			bad_format_email: FapFapError.emailFormat,
			subscription_error: FapFapError.emailFormat,
			bad_length_email: FapFapError.emailLength,
			required_username: FapFapError.requiredUsername,
			required_email: FapFapError.requiredEmail,
			required_pwd: FapFapError.requiredPassword,
			user_not_active: FapFapError.userNotActive,
			user_not_found: FapFapError.badCredentials,
			incorrect_password: FapFapError.badCredentials,
			max_device: FapFapError.maxDeviceReach,
			no_device: FapFapError.NoDevice,
			unauthorized_device: FapFapError.unauthorizedDevice,
			device_not_found: FapFapError.deviceNotFound,
			spam_protection: FapFapError.rateLimited,
		}

		return errorMap[code]?.() ?? null
	}
}

export class WaitForEventError extends FapFapError {
	constructor() {
		super('Wait for event', 'wait_for_event')
	}
}

export class InvalidRewardTokenCodeError extends FapFapError {
	constructor() {
		super('Invalid Reward Token code', 'invalid_reward_token_code')
	}
}

export class DuplicateFavoriteError extends FapFapError {
	constructor() {
		super('Duplicate favorite', 'duplicate_favorite')
	}
}

export class FavoritesLimitExceededError extends FapFapError {
	constructor() {
		super('Favorites limit exceeded', 'favorites_limit_exceeded')
	}
}

export class ImageNotFound extends FapFapError {
	constructor() {
		super('Image not found', 'image_not_found')
	}
}

export class UnauthorizedResource extends FapFapError {
	constructor() {
		super('Access denied: User lacks the necessary authorization to access this resource.', 'unauthorized_resource')
	}
}

export class DuplicatePresetError extends FapFapError {
	constructor() {
		super('Duplicate preset', 'duplicate_filter_set')
	}
}

export class UsernameError extends FapFapError {
	constructor(message: string, code: string) {
		super(message, code)
	}
}

export class EmailError extends FapFapError {
	constructor(message: string, code: string) {
		super(message, code)
	}
}

export class PasswordError extends FapFapError {
	constructor(message: string, code: string) {
		super(message, code)
	}
}

export class LoginError extends FapFapError {
	constructor(message: string, code: string) {
		super(message, code)
	}
}

export class DeviceError extends FapFapError {
	constructor(message: string, code: string) {
		super(message, code)
	}
}

export class DuplicateUsernameError extends UsernameError {
	constructor() {
		super('Duplicate username', 'duplicate_username')
	}
}

export class UsernameFormatError extends UsernameError {
	constructor() {
		super('Invalid characters in username', 'bad_format_username')
	}
}

export class UsernameLengthError extends UsernameError {
	constructor() {
		super('Invalid username length (must be 3-12 characters)', 'bad_length_username')
	}
}

export class RequiredUsernameError extends UsernameError {
	constructor() {
		super('Username is required', 'required_username')
	}
}

export class DuplicateEmailError extends EmailError {
	constructor() {
		super('Duplicate email', 'duplicate_email')
	}
}

export class RequiredEmailError extends EmailError {
	constructor() {
		super('Email is required', 'required_email')
	}
}

export class EmailFormatError extends EmailError {
	constructor() {
		super('Invalid email format', 'bad_format_email')
	}
}

export class EmailLengthError extends EmailError {
	constructor() {
		super('Invalid email length (must be < 100 characters)', 'bad_length_email')
	}
}

export class PasswordLengthError extends PasswordError {
	constructor() {
		super('Invalid password length (must be 8-32 characters)', 'bad_length_pwd')
	}
}

export class RequiredPasswordError extends PasswordError {
	constructor() {
		super('Password is required', 'required_pwd')
	}
}

export class BadCredentialsError extends LoginError {
	constructor() {
		super('Username or password is invalid', 'error')
	}
}

export class UserNotActiveError extends LoginError {
	constructor() {
		super('User not active', 'user_not_active')
	}
}

export class UserMaxDeviceError extends DeviceError {
	constructor() {
		super('Max device reached', 'max_device')
	}
}

export class UserUnauthorizedDeviceError extends DeviceError {
	constructor() {
		super('Unauthorized device', 'unauthorized_device')
	}
}

export class UserNoDeviceError extends DeviceError {
	constructor() {
		super('No device', 'no_device')
	}
}

export class UserDeviceNotFoundError extends DeviceError {
	constructor() {
		super('Device not found', 'device_not_found')
	}
}

export class RateLimitedError extends FapFapError {
	constructor() {
		super('Rate limited', 'spam_protection')
	}
}
