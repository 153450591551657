<script setup lang="ts">
import { computed, defineAsyncComponent, shallowRef, watch } from 'vue'
import { Flag, Flame, Mouse, TrendingUp } from 'lucide-vue-next'
import TabMenu from 'primevue/tabmenu'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useScrollDirectionEvent } from '@/composables/useScrollDirectionEvent'
import { useLeaderboardStore } from '@/stores/leaderboardStore'

const PodiumEntry = defineAsyncComponent(() => import('@/components/game/leaderboard/PodiumEntry.vue'))
const LeaderboardRow = defineAsyncComponent(() => import('@/components/game/leaderboard/LeaderboardRow.vue'))

const { scrollDirection, scrollPosition } = useScrollDirectionEvent()
const { t } = useI18n()

const leaderboardStore = useLeaderboardStore()
const { activeTab, podiumToDisplay, users, podiumUsers, loggedUser } = storeToRefs(leaderboardStore)

const enableIntersect = shallowRef<boolean>(true)

const tabs = computed((): any => [
	{ label: 'level', icon: TrendingUp },
	{ label: 'scroll', icon: Mouse },
	{ label: 'report', icon: Flag },
	{ label: 'streak', icon: Flame },
])

watch(() => activeTab.value, () => {
	enableIntersect.value = false
	setTimeout(() => {
		enableIntersect.value = true
	}, 100)
})
</script>

<template>
	<div class="mt-[50px] mb-[5px] w-full flex flex-col h-[calc(100vh-60px)]">
		<h1 class="p-0 m-0 mt-[20px] text-3xl md:text-4xl flex justify-center w-full font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-yellow-100 to-orange-600 pb-2 text-center">
			{{ t('menu.leaderboard') }}
		</h1>
		<div>
			<TabMenu
				v-model:active-index="activeTab"
				:model="tabs" :pt="{
					root: {
						class: [
							'text-white w-full sticky z-[7] transform',
							{
								'move-up-tab': scrollDirection === 'up' && scrollPosition > 400,
								'move-down-tab': scrollDirection !== 'up' || scrollPosition <= 400,
							},
						],
					},
					menu: {
						class: 'bg-gray-950',
					},
					inkbar: {
						class: 'bg-orange-400',
					},
					action: {
						class: '!bg-gray-950',
					},
					menuitem: {
						class: 'w-4/12',
					},
				}"
			>
				<template #item="{ item, props }">
					<a v-bind="props.action" :id="item.label as string" class="flex flex-col items-center gap-2">
						<div class="flex justify-center items-center gap-1">
							<component :is="item.icon" class="hidden md:block lucide-button-icon" />
							<span class="font-semibold md:font-bold text-sm md:text-base">{{ t(`leaderboard.tab.${item.label as string}`) }}</span>
						</div>
					</a>
				</template>
			</TabMenu>

			<div class="w-full flex flex-col md:w-[500px] mx-auto md:p-0 px-7 pb-4">
				<div class="w-full text-center mt-4 -mb-4 font-medium text-sm md:text-base">
					{{ t('leaderboard.tab.header', { value: t(`leaderboard.tab.header_values.${tabs[activeTab].label}`) }) }}
				</div>

				<div v-if="podiumToDisplay" class="podium">
					<PodiumEntry v-if="podiumToDisplay >= 1" :equipped="podiumUsers[0].player.equipped" :user="users[0]" :title="podiumUsers[0].player.title" />
					<div class="hidden md:flex flex-nowrap">
						<PodiumEntry v-if="podiumToDisplay >= 3" :equipped="podiumUsers[2].player.equipped" :user="users[2]" :title="podiumUsers[2].player.title" />
						<PodiumEntry v-if="podiumToDisplay >= 2" :equipped="podiumUsers[1].player.equipped" :user="users[1]" :title="podiumUsers[1].player.title" />
					</div>
					<Divider class="block md:hidden" />
					<PodiumEntry v-if="podiumToDisplay >= 2" :equipped="podiumUsers[1].player.equipped" :user="users[1]" :title="podiumUsers[1].player.title" class="block md:hidden" />
					<Divider class="block md:hidden" />
					<PodiumEntry v-if="podiumToDisplay >= 3" :equipped="podiumUsers[2].player.equipped" :user="users[2]" :title="podiumUsers[2].player.title" class="block md:hidden" />
				</div>
				<div class="leaderboard">
					<LeaderboardRow v-if="loggedUser" :user="loggedUser" :is-logged-user="true" />
					<LeaderboardRow v-for="(user, index) in users.slice(3)" :key="index" :user="user" :is-logged-user="false" />
					<div v-if="enableIntersect" v-intersect="leaderboardStore.load" class="w-full h-[30px] z-[-1] mb-[50px]" />
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.podium {
	@apply w-full flex justify-center items-center flex-col py-10;
}
.podium-rank-1 {
	@apply flex flex-col justify-center items-center gap-2 font-extrabold text-xl md:text-2xl text-yellow-200;
}
.podium-rank {
	@apply flex flex-col justify-center items-center gap-2 font-extrabold text-base md:text-lg w-[80px] md:w-[120px];
}

.leaderboard {
	@apply flex flex-col w-full gap-2;
}

.row {
	@apply flex flex-nowrap items-center  text-sm md:text-base  p-2 odd:bg-gray-900 even:bg-gray-800 rounded-md gap-3 cursor-pointer duration-300;
	&:hover {
		@apply bg-gray-700 scale-105;
	}
	.rank {
		@apply font-bold;
	}
}

.move-up-tab {
	top: 0px !important;
	transition: top 0.3s ease-in-out;
}

.move-down-tab {
	top: 50px;
	transition: top 0.3s ease-in-out;
}
</style>
