import { defineStore } from 'pinia'
import { computed, inject, ref } from 'vue'
import type { IImage } from '@/types'
import { EImageGalleryType } from '@/types'
import { RequestAction, type RequestRouter } from '@/request/requestRouter'

export const useFavoriteStore = defineStore('favorite images', () => {
	const request = inject<RequestRouter>('request')

	const images = ref<Array<IImage>>([])

	const index = ref<number>(-1)
	const size = 12
	let page = 0

	const toDelete = ref<string[]>([])

	const loadMoreImages = async (): Promise<void> => {
		const fetchedImages = await request?.exec(RequestAction.GetGallery, {
			routeParams: {
				page: ++page,
				size,
				route: EImageGalleryType.Favorite,
			},
			body: {
				scene: 0,
				filter: {},
			},
		})

		if (fetchedImages) {
			images.value = [
				...images.value,
				...fetchedImages.items,
			]
		}
		else {
			console.error('Failed to fetch images')
		}
	}

	const setZoomedImage = async (_index: number) => {
		if (_index < 0) {
			throw new Error('Index .')
		}
		else {
			index.value = _index
		}
	}

	const reset = () => {
		page = 0
		index.value = -1
		images.value = []
	}

	const deleteImage = (uuid: string): void => {
		const image = images.value.find(img => img.uuid === uuid)
		if (image) {
			const i = images.value.indexOf(image)
			images.value.splice(i, 1)
		}
	}

	return {
		images,
		page,
		size,
		index,
		toDelete,
		loadMoreImages,
		setZoomedImage,
		deleteImage,
		reset,
	}
})
