import { defineStore } from 'pinia'
import { ref, shallowRef, watch } from 'vue'
import { addYears } from 'date-fns'
import Tools from '@/utils/tools'

export const useAudioStore = defineStore('audioStore', () => {
	const tracks = ref<Record<string, (() => HTMLAudioElement) | HTMLAudioElement>>({})

	const muted = shallowRef(false) // Temporary mute
	const audioEnabled = shallowRef(Tools.getCookie('audio') === 'true') // Full mute

	const setCookie = () => {
		Tools.setCookie(
			'audio',
			JSON.stringify(audioEnabled.value),
			addYears(new Date(), 1),
			`.${import.meta.env.VITE_DOMAIN}`,
			'/',
		)
	}

	if (Tools.getCookie('audio') === null) {
		audioEnabled.value = true
		setCookie()
	}
	// Register a single track
	function registerTrack(trackName: string, path: string) {
		if (tracks.value[trackName]) {
			throw new Error(`${trackName} is already registered.`)
		}
		tracks.value[trackName] = () => new Audio(path) // avoid loading all audios on first landing
	}

	// Register multiple tracks
	function registerTracks(...tracksToAdd: { trackName: string, path: string }[]) {
		tracksToAdd.forEach(({ trackName, path }) => registerTrack(trackName, path))
	}

	// Play a specific track
	function play(trackName: string, volume: number = 0.025) {
		if (!audioEnabled.value || muted.value)
			return // If muted or disabled, do nothing

		let track = tracks.value[trackName]
		if (typeof track === 'function') {
			tracks.value[trackName] = track() // load track
			track = tracks.value[trackName]
		}
		if (!track) {
			throw new Error(`${trackName} is not registered.`)
		}
		track.currentTime = 0
		track.volume = volume
		track.play()
	}

	// Update volume of all tracks based on mute status
	function updateTrackVolumes() {
		const volume = muted.value || !audioEnabled.value ? 0 : 0.025
		Object.values(tracks.value).forEach(track => ((track as HTMLAudioElement).volume = volume))
	}

	// Watch for changes in mute status
	watch(() => muted.value, updateTrackVolumes)

	// Watch for changes in audio enabled status
	watch(() => audioEnabled.value, () => {
		updateTrackVolumes()
		setCookie()
	})

	return {
		play,
		registerTrack,
		registerTracks,
		muted, // Temporary mute
		audioEnabled, // Full mute
	}
})
