import { defineStore } from 'pinia'
import { inject, ref } from 'vue'
import type { IMembershipTierAPI } from '@/types'
import { RequestAction, type RequestRouter } from '@/request/requestRouter'

export const useCheckoutPremiumStore = defineStore('checkoutPremiumStore', () => {
	const request = inject<RequestRouter>('request')

	const price = ref<number>(0)
	const duration = ref<number>(1)

	const tiers = ref<IMembershipTierAPI[]>()
	const tiersLoaded = ref<boolean>(false)

	const queryTiers = async () => {
		if (tiersLoaded.value) {
			return
		}
		try {
			const tiersData = await request?.exec(RequestAction.GetMembershipTiers)
			if (tiersData) {
				tiers.value = tiersData
				tiersLoaded.value = true
			}
			else {
				tiersLoaded.value = false
			}
		}
		catch (err) {
			console.error('An error occurred while querying membership tiers:', err)
			tiersLoaded.value = false
		}
	}

	const getTiers = (): IMembershipTierAPI[] => {
		if (tiersLoaded.value && tiers.value) {
			return tiers.value
		}
		return []
	}

	return { price, duration, tiersLoaded, queryTiers, getTiers }
})
