import type { IEquippedItems, IItemAnimationType, IItemReward, IItemRewardContainer } from '@/types'
import { EItemRewardType } from '@/types'

export function IItemRewardFactory<T extends string | number[] | [IItemAnimationType, number[]]>(
	data: Partial<IItemReward<T>> = {},
): IItemReward<T> {
	return {
		id: 0,
		tier: 'C',
		data: '' as T,
		...data,
	}
}

export function IItemRewardContainerFactory(): IItemRewardContainer {
	return {
		[EItemRewardType.Animation]: [],
		[EItemRewardType.Style]: [],
		[EItemRewardType.Color]: [],
		[EItemRewardType.ProfilePic]: [],
	}
}

export function IEquippedItemsFactory(data: Partial<IEquippedItems> = {}): IEquippedItems {
	return {
		[EItemRewardType.Animation]: IItemRewardFactory<[IItemAnimationType, number[]]>({
			...data[EItemRewardType.Animation],
			data: data[EItemRewardType.Animation]?.data || ['static', [0, 0, 1, 1]], // Default for Animation
		}),
		[EItemRewardType.Style]: IItemRewardFactory<number[]>({
			...data[EItemRewardType.Style],
			data: data[EItemRewardType.Style]?.data || [0, 100], // Default for Style
		}),
		[EItemRewardType.Color]: IItemRewardFactory<string>({
			...data[EItemRewardType.Color],
			data: data[EItemRewardType.Color]?.data || '#1E90FF', // Default for Color
		}),
		[EItemRewardType.ProfilePic]: IItemRewardFactory<string>({
			...data[EItemRewardType.ProfilePic],
			data: data[EItemRewardType.ProfilePic]?.data ?? 'pp1.webp',
		}),
	}
}
