<script setup lang="ts">
import { defineAsyncComponent, inject, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import { Crown, Lock } from 'lucide-vue-next'
import ProgressSpinner from 'primevue/progressspinner'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import Card from 'primevue/card'
import { useGalleryStore } from '@/stores/galleryStore'
import Tools from '@/utils/tools'
import LockableVariable from '@/utils/lockableVariable'
import { useCompatibilityStore } from '@/stores/compatibilityStore'
import router from '@/router'
import { useSettingStore } from '@/stores/settingStore'
import { useFavoriteStore } from '@/stores/favoriteStore'
import type { IFilterAPI, IImage, IImageGallery, IItemRewardTier, ImageReaction } from '@/types'
import { EImageGalleryType } from '@/types'
import { useAuthStore } from '@/stores/authStore'
import { RequestAction, type RequestRouter } from '@/request/requestRouter'
import { ImageNotFound, UnauthorizedResource } from '@/errors/FapFapError'
import { useFilterStore } from '@/stores/filterStore'
import { useEventGalleryStore } from '@/stores/eventGalleryStore'
import { useAutoScroll } from '@/composables/useAutoScroll'
import { ImageReactionFactory, ImageReactionFactoryFromImage } from '@/types/factory'

// Store is empty in case of a direct link to an image
const props = defineProps<Props>()

const ImageMenu = defineAsyncComponent(() => import('@/components/ImageMenu.vue'))

interface Props {
	uuid: string
	previousRoute?: string
}

const request = inject<RequestRouter>('request')
const { t } = useI18n()
const authStore = useAuthStore()
const settingStore = useSettingStore()
const filterStore = useFilterStore()
const maxPage = ref<number>(Infinity)
const imageSrc = ref<string>('')
const uuid = ref<string>('')
const reaction = ref<ImageReaction>(ImageReactionFactory())
const rank = ref<IItemRewardTier>('S')
const imageFilters = ref<Record<string, number>>({})
const isFavorite = ref<boolean>(false)
const markForDeletion = ref<boolean>(false)
const imageElement = ref<HTMLImageElement>()
const galleryStore = useGalleryStore()
const favoriteStore = useFavoriteStore()
const bundleStore = useEventGalleryStore()
const compat = useCompatibilityStore()
const { seed, timestamp, galleryType, imageFullScreen, restoreScroll } = storeToRefs(settingStore)
const { user } = storeToRefs(authStore)
const { fullscreenScroll, stop: stopAutoScroll } = useAutoScroll()
const img = new Image()
const loading = ref(true)
let imageLoading: AbortController
let pageOffset: number = 1
let imageStore: typeof galleryStore | typeof favoriteStore | typeof bundleStore = galleryStore

if (galleryType.value === EImageGalleryType.Favorite) {
	imageStore = favoriteStore
}

if (galleryType.value === EImageGalleryType.Gallery) {
	imageStore = galleryStore
}

if (galleryType.value === EImageGalleryType.Event) {
	imageStore = bundleStore
}

const { images } = storeToRefs(imageStore)
let directLink = !images.value.length
const unauthorizedAccess = ref<boolean>(false)
const loadNextImage = {
	ArrowDown: () => {
		if (imageStore.index + 1 < maxPage.value) {
			imageStore.index++
		}
		else {
			stopAutoScroll()
		}
	},
	ArrowUp: () => {
		if (imageStore.index > 0) {
			imageStore.index--
		}
	},

}

function handleKeydown(event: KeyboardEvent) {
	if (event.key === 'Escape') {
		unmountComponent()
	}
	if (imageFullScreen.value && (
		event.key === 'ArrowDown'
		|| event.key === 'ArrowUp'
		|| event.key === 'PageDown'
		|| event.key === 'PageUp'
		|| event.key === ' '
	)) {
		event.preventDefault()
	}

	if (imageFullScreen.value && (
		event.key === 'ArrowDown'
		|| event.key === 'ArrowUp')) {
		loadNextImage[event.key]()
	}
}

function handleWheel(event: WheelEvent) {
	if (imageFullScreen.value) {
		event.preventDefault()
		const dir = event.deltaY > 0 ? 'ArrowDown' : 'ArrowUp'

		loadNextImage[dir]()
	}
}

async function setImageInfo(): Promise<void> {
	try {
		let imageData = await request?.exec(RequestAction.GetImageInfo, {
			routeParams: {
				uuid: uuid.value,
			},
		})
		if (imageData) {
			imageData = imageData as IImage
			if ('is_favorite' in imageData) {
				isFavorite.value = imageData.is_favorite
			}
			if ('filters' in imageData) {
				imageFilters.value = imageData.filters
			}
			if ('rank' in imageData) {
				rank.value = imageData.rank
			}
			reaction.value = ImageReactionFactoryFromImage(imageData)
		}
		else {
			router.back()
		}
	}
	catch (error: any) {
		if (error instanceof UnauthorizedResource) {
			unauthorizedAccess.value = true
			loading.value = false
		}
		if (error instanceof ImageNotFound) {
			imageFullScreen.value = false
			router.push('/404')
		}
	}
}

watch(() => imageStore.index, async (index) => {
	imageLoading?.abort()

	if (directLink && !index) {
		uuid.value = props.uuid ?? ''
		await setImageInfo()
		downloadImage(uuid.value)
		history.replaceState({}, '', `/image/${uuid.value}`)
	}
	else {
		if (galleryType.value === EImageGalleryType.Event) {
			loadImageFromStore(index)
		}
		else {
			imageLoading = loadImage(index)
		}
	}
})

async function handleDirectLink() {
	await setImageInfo()
	pageOffset = Tools.random(1, await getImageCount())
	imageStore.index = 0
}

onMounted(async () => {
	Tools.toggleScrollbar(false)
	fullscreenScroll.value = true
	imageFullScreen.value = true
	window.addEventListener('keydown', handleKeydown)
	uuid.value = props.uuid ?? ''

	if (!uuid.value.length) {
		fullscreenScroll.value = false
		imageFullScreen.value = false
		return
	}

	if (directLink) {
		await handleDirectLink()
	}
	else {
		try {
		// enter this condition after clickin g on an image from the main gallery or favorites
			isFavorite.value = images.value[imageStore.index].is_favorite
			imageFilters.value = images.value[imageStore.index].filters
			rank.value = images.value[imageStore.index].rank
			reaction.value = ImageReactionFactoryFromImage(images.value[imageStore.index])
			downloadImage(props.uuid ?? '')
			history.pushState({
				current: galleryType.value === EImageGalleryType.Gallery ? '/' : '/favorites',
			}, '', `/image/${uuid.value}`)
		}
		catch {
			directLink = true
			await handleDirectLink()
		}
	}
})

function unmountComponent() {
	imageLoading?.abort()
	imageFullScreen.value = false
	if (!directLink) {
		history.replaceState({
			current: `/image/${uuid.value}`,
		}, '', props.previousRoute ?? '/')
		restoreScroll.value()
	}
	else {
		router.push('/')
	}
}

onUnmounted(() => {
	Tools.toggleScrollbar(true)
	fullscreenScroll.value = false
	imageFullScreen.value = false
	window.removeEventListener('keydown', handleKeydown)
})

let v: number[] = [0, 0]
let dir: number[] = [0, 0]
const touchIdentifier = new LockableVariable<number>(0)

function resetImagePosition() {
	if (imageElement.value) {
		imageElement.value.style.transform = `translate(0px, 0px)`
		imageElement.value.style.width = ''
		imageElement.value.style.opacity = ''
	}
	v = [0, 0]
	dir = [0, 0]
}

function touchStart(event: TouchEvent) {
	if (imageFullScreen.value && touchIdentifier.isUnlocked()) {
		touchIdentifier.setValue(event.touches.item(0)?.identifier ?? 0).lock()

		resetImagePosition()
		v = [
			event.touches[0].clientX,
			event.touches[0].clientY,
		]
	}
}

function handleTouchMove(event: TouchEvent) {
	event.preventDefault()

	let found = false

	for (let i = 0; i < event.touches.length; i++) {
		const touch: Touch = event.touches[i]

		if (touch.identifier === touchIdentifier.getValue()) {
			found = true
			break
		}
	}
	if (imageFullScreen.value && found && touchIdentifier.isLocked()) {
		const v2 = [
			event.touches[0].clientX,
			event.touches[0].clientY,
		]

		dir = [
			Math.ceil(v2[0] - v[0]),
			Math.ceil(v2[1] - v[1]),
		]
		const length = Math.sqrt(dir[0] ** 2 + dir[1] ** 2)

		if (imageElement.value) {
			imageElement.value.style.transform = `translate(${dir[0]}px, ${dir[1]}px)`
			imageElement.value.style.width = `${Tools.map(length, 0, 500, 100, 0)}%`

			if (length >= 30) {
				imageElement.value.style.opacity = `${Tools.map(length, 30, 100, 1, 0)}`
			}
			else {
				imageElement.value.style.opacity = ''
			}
		}
	}
}

function touchEnd(event: TouchEvent) {
	let found = false

	for (let i = 0; i < event.changedTouches.length; i++) {
		const touch: Touch = event.changedTouches[i]

		if (touch.identifier === touchIdentifier.getValue()) {
			found = true
			break
		}
	}

	if (v[0] !== 0 && found) {
		const length = Math.sqrt(dir[0] ** 2 + dir[1] ** 2)

		if (length >= 100) {
			if (dir[1] > 0) {
				loadNextImage.ArrowUp()
			}
			else {
				loadNextImage.ArrowDown()
			}
		}
		resetImagePosition()
		touchIdentifier.unlock()
	}
}

function loadImageFromStore(index: number) {
	if (index < images.value.length) {
		const image = images.value[index]
		uuid.value = image.uuid
		isFavorite.value = image.is_favorite
		rank.value = image.rank
		reaction.value = ImageReactionFactoryFromImage(image)
		history.replaceState({}, '', `/image/${uuid.value}`)
		downloadImage(uuid.value)
	}
	else {
		stopAutoScroll()
		imageStore.index--
	}
}

function loadImage(index: number) {
	loading.value = true
	const abortController = new AbortController()

	const headers = new Headers()
	if (galleryType.value === EImageGalleryType.Gallery) {
		headers.append('Seed', seed.value.toString())
		headers.append('Timestamp', timestamp.value)
	}
	headers.append('Content-Type', 'application/json')

	const fsDirect = galleryType.value === EImageGalleryType.Gallery && !directLink

	const body: IFilterAPI | undefined = fsDirect ? filterStore.computedFilters : undefined

	request?.exec(RequestAction.GetGallery, {
		env: {
			headers,
			abortController,
		},
		routeParams: {
			page: index + pageOffset,
			size: 1,
			route: galleryType.value,
		},
		body,
	}).then((data: IImageGallery) => {
		if (data.page > data.total) {
			imageStore.index--
		}
		else {
			if ('pages' in data) {
				maxPage.value = data.pages
			}
			if ('items' in data) {
				uuid.value = data.items[0].uuid
				isFavorite.value = data.items[0].is_favorite
				imageFilters.value = data.items[0].filters
				rank.value = data.items[0].rank
				reaction.value = ImageReactionFactoryFromImage(data.items[0])
				history.replaceState({}, '', `/image/${uuid.value}`)
				downloadImage(uuid.value)
			}
		}
	})
		.catch((error) => {
			console.error(error)
		})
	return abortController
}

function downloadImage(imgSrc: string) {
	imageSrc.value = `${import.meta.env.VITE_CLOUDFLARE_URL}/${imgSrc}.webp`

	img.onload = () => {
		loading.value = false
	}

	img.onerror = () => {
		unauthorizedAccess.value = true
		loading.value = false
	}

	img.src = imageSrc.value
}

async function getImageCount(): Promise<number> {
	const data = await request?.exec(RequestAction.GetGallery, {
		env: {
			headers: {
				Seed: seed.value.toString(),
			},
		},
		routeParams: {
			page: 1,
			size: 1,
			route: EImageGalleryType.Gallery,
		},
	})
	if (data) {
		return data.total
	}
	return 0
}
</script>

<template>
	<div
		v-if="imageFullScreen" id="container" @wheel="handleWheel" @touchstart="touchStart"
		@touchmove="handleTouchMove" @touchend="touchEnd"
	>
		<Button
			icon="pi pi-times" rounded text aria-label="Filter" severity="help"
			class="sm:top-[10px] sm:right-[10px] top-[4px] right-0 z-20 absolute bg-transparent hover:bg-orange-400 flex-center border-none rounded-full cursor-pointer [&>*]:hover:text-black"
			size="large" @click.stop.prevent="unmountComponent" @touchend.stop.prevent="unmountComponent"
		/>

		<div class="image-container">
			<ProgressSpinner v-if="loading && !unauthorizedAccess" />
			<img
				v-else-if="!loading && !unauthorizedAccess"
				ref="imageElement"
				:src="imageSrc"
				alt="Zoomed Image"
				class="w-full h-auto max-h-full object-contain z-[14]"
				:class="markForDeletion ? 'opacity-50' : 'opacity-100'"
			>
			<Card v-else-if="!user.premium.active" class="bg-gray-900 shadow-md shadow-black/50 w-[30rem] ml-2 mr-2 text-sm md:text-base">
				<template #title>
					<div class="flex items-center gap-2">
						<Lock :size="18" /> {{ t(`notification.unauthorized_resource.title`) }}
					</div>
				</template>
				<template #content>
					<p class="m-0 p-1">
						{{ t(`notification.unauthorized_resource.message`) }}
					</p>
				</template>
				<template #footer>
					<router-link to="/premium" target="_blank" class="w-full xs:grow">
						<Button class="w-full xs:grow">
							<template #default>
								<div class="flex gap-1 items-center justify-center w-full">
									<Crown :size="18" /> {{ t('menu.go_premium') }}
								</div>
							</template>
						</Button>
					</router-link>
				</template>
			</Card>
		</div>

		<div
			v-if="!compat.mobileDevice && !unauthorizedAccess"
			class="md:flex hidden flex-col justify-center items-center fixed right-[10px] top-1/2 transform -translate-y-1/2 rounded-[6px] w-12 bg-gray-800 overflow-hidden z-[15] border-2 border-gray-700 border-solid"
		>
			<span
				class="w-full flex justify-center items-center h-12 hover:bg-orange-500 [&_i]:hover:text-black cursor-pointer transform duration-300"
				@click="loadNextImage.ArrowUp"
			>
				<i class="pi pi-chevron-up text-base md:text-lg" size="large" @touchend="loadNextImage.ArrowUp" />
			</span>
			<span class="w-full h-[2px] bg-gray-700" />
			<span
				class="w-full flex justify-center items-center h-12 hover:bg-orange-500 [&_i]:hover:text-black cursor-pointer transform duration-300"
				@click="loadNextImage.ArrowDown"
			>
				<i class="pi pi-chevron-down text-base md:text-lg" @touchend="loadNextImage.ArrowDown" />
			</span>
		</div>
		<ImageMenu
			v-if="!unauthorizedAccess"
			v-model:isFavorite="isFavorite"
			:reaction="reaction"
			:rank="rank"
			:index="imageStore.index"
			:uuid="uuid"
			:show-menu="true"
			:filters="imageFilters"
			class-menu="image-zoomed-menu"
			:vertical="true"
			:disable-similar-content="directLink"
			@next-image="loadNextImage.ArrowDown"
			@update:mark-for-deletion="(state: boolean) => markForDeletion = state"
			@exit-full-screen="unmountComponent"
		/>
	</div>
</template>

<style>
.image-zoomed-menu {
	@apply bottom-0 right-0 z-[14];
}
</style>

<style scoped lang="scss">
#container {
	@apply fixed flex justify-center items-center flex-col top-0 left-0 w-screen h-screen z-[13] bg-gray-950;
}

.image-container {
	@apply w-screen h-screen flex justify-center items-center overflow-hidden;
}
</style>
