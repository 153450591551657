import type { IImage, ImageReaction } from '@/types'

export function ImageReactionFactory(data: Partial<ImageReaction> = {}): ImageReaction {
	return {
		good_react_cnt: 0,
		bad_react_cnt: 0,
		neutral_react_cnt: 0,
		user_react: null,
		...data,
	} as ImageReaction
}

export function ImageReactionFactoryFromImage(data: Partial<IImage> = {}): ImageReaction {
	return ImageReactionFactory({
		good_react_cnt: data?.good_react_cnt ?? 0,
		bad_react_cnt: data?.bad_react_cnt ?? 0,
		neutral_react_cnt: data?.neutral_react_cnt ?? 0,
		user_react: data?.user_react ?? null,
	})
}
