import { formatInTimeZone } from 'date-fns-tz'
import { i18n } from '@/i18n'

const { t } = i18n.global

export default class Tools {
	private static debouncers: Map<Function, ReturnType<typeof setTimeout>> = new Map()

	/**
	 * Debounces a function by delaying its execution.
	 * @param fn The function to be debounced.
	 * @param delay The delay in milliseconds before executing the debounced function.
	 */
	static applyDebounce(fn: Function, delay: number): void {
		let id: ReturnType<typeof setTimeout> | undefined = this.debouncers.get(fn)

		if (id) {
			clearTimeout(id)
			this.debouncers.delete(fn)
		}

		id = setTimeout(() => {
			fn()
		}, delay)

		this.debouncers.set(fn, id)
	};

	/**
	 * Creates a debouncer function that delays the execution of a given function.
	 * @param func The function to be debounced.
	 * @param delay The delay in milliseconds before executing the debounced function.
	 * @returns A debounced function that can be called to delay the execution of the original function.
	 */
	static createDebouncedFunction(func: Function, delay: number): (...args: any[]) => void {
		let timeoutId: ReturnType<typeof setTimeout>

		return (...args: any[]) => {
			clearTimeout(timeoutId)
			timeoutId = setTimeout(() => {
				func(...args)
			}, delay)
		}
	};

	/**
	 * Maps a value from one range to another range.
	 *
	 * @param value The number to map.
	 * @param from1 Lower bound of the value's current range.
	 * @param to1 Upper bound of the value's current range.
	 * @param from2 Lower bound of the value's target range.
	 * @param to2 Upper bound of the value's target range.
	 * @returns The number mapped to the new range.
	 */
	public static map(value: number, from1: number, to1: number, from2: number, to2: number): number {
		return (value - from1) / (to1 - from1) * (to2 - from2) + from2
	}

	/**
	 * Clamps a value between a minimum and maximum value.
	 *
	 * @param value The number to clamp.
	 * @param min The minimum value to clamp to.
	 * @param max The maximum value to clamp to.
	 * @returns The clamped value.
	 */
	public static clamp(value: number, min: number, max: number): number {
		return Math.max(min, Math.min(max, value))
	}

	/**
	 * Linearly interpolates between two values.
	 *
	 * @param a The start value.
	 * @param b The end value.
	 * @param t The interpolation factor between 0 and 1.
	 * @returns The interpolated value.
	 */
	public static lerp(a: number, b: number, t: number): number {
		return a + (b - a) * Tools.clamp(t, 0, 1)
	}

	/**
	 * Generates a random number between a minimum and maximum value.
	 *
	 * @param min The minimum value.
	 * @param max The maximum value.
	 * @returns A random number between min and max (inclusive).
	 */
	public static random(min: number, max: number): number {
		return Math.floor(Math.random() * (max - min + 1)) + min
	}

	/**
	 * Retrieves the value of a cookie by its name.
	 *
	 * @param name The name of the cookie.
	 * @returns The value of the cookie, or null if the cookie does not exist.
	 */
	public static getCookie(name: string): string | null {
		const cookies = document.cookie.split(';')
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i].trim()
			if (cookie.startsWith(`${name}=`)) {
				return cookie.substring(name.length + 1)
			}
		}
		return null
	}

	/**
	 * Provides spam protection by limiting the frequency of callback executions.
	 *
	 * @param {Function} callback - The function to be executed when the protection allows.
	 * @param {number} [intervalThreshold] - The maximum average interval (in milliseconds) between callback executions allowed before locking.
	 * @param {number} [lockDuration] - The duration (in milliseconds) for which the function will be locked once spam is detected.
	 * @param {number} [stackSize] - The number of intervals to consider for calculating the average interval.
	 * @returns {Function} - A function that, when invoked, executes the callback if spam protection allows. It returns the time left in seconds until the lock is lifted if locked, or 0 if not locked.
	 *
	 * @example
	 * const protectedFunction = spamProtection(() => console.log('Executed'), 1000, 2000, 5);
	 *
	 * // Execute the function multiple times
	 * protectedFunction(); // Executes the callback
	 * protectedFunction(); // May lock if called too frequently
	 *
	 * // When locked, it will return the time left in seconds until the lock is lifted
	 * const timeLeft = protectedFunction(); // e.g., 1.8 seconds
	 */
	public static spamProtection(callback: Function, intervalThreshold: number = 1000, lockDuration: number = 1000, stackSize: number = 5): () => number {
		let intervals: number[] = []
		let locked: boolean = false
		let lockStart: number = 0

		const lock = () => {
			lockStart = Date.now()
			locked = true
			setTimeout(() => {
				locked = false
			}, lockDuration)
		}

		const timeLeft = (): number => {
			return Number(((lockDuration - (Date.now() - lockStart)) / 1000).toFixed(1))
		}
		return () => {
			if (locked) {
				return timeLeft()
			}

			intervals.push(Date.now())

			if (!locked && intervals.length > stackSize) {
				let average = 0

				for (let i = 0; i < intervals.length; i++) {
					if (i + 1 >= intervals.length) {
						break
					}
					average += intervals[i + 1] - intervals[i]
				}
				average /= intervals.length
				intervals = []
				if (average <= intervalThreshold) {
					lock()
					return timeLeft()
				}
			}
			callback()
			return 0
		}
	}

	/**
	 * Sets a cookie with the given name and value.
	 *
	 * @param name The name of the cookie.
	 * @param value The value of the cookie.
	 * @param expires The expiration date of the cookie (optional).
	 * @param domain The domain of the cookie (optional).
	 * @param path The path of the cookie (optional).
	 */
	public static setCookie(name: string, value: string, expires?: Date, domain?: string, path?: string): void {
		let cookie = `${name}=${value}`
		if (expires) {
			cookie += `; expires=${expires.toUTCString()}`
		}
		if (path) {
			cookie += `; path=${path}`
		}
		if (domain) {
			cookie += `; domain=${domain}`
		}
		document.cookie = cookie
	}

	/**
	 * Deletes a cookie by its name.
	 *
	 * @param name The name of the cookie to delete.
	 * @param domain The domain of the cookie (optional).
	 * @param path The path of the cookie (optional).
	 */
	public static deleteCookie(name: string, domain?: string, path?: string): void {
		if (Tools.getCookie(name)) {
			Tools.setCookie(name, '', new Date(0), domain, path)
		}
	}

	/**
	 * Capitalizes the first letter of a string.
	 *
	 * @param str The string to capitalize.
	 * @returns The string with the first letter capitalized.
	 */
	public static capitalizeFirstLetter(str: string): string {
		return str.charAt(0).toUpperCase() + str.slice(1)
	}

	/**
	 * Detects if an ad blocker is enabled.
	 *
	 * @returns A promise that resolves to a boolean indicating if the ad blocker is enabled.
	 */
	public static async detectAdBlock(): Promise<boolean> {
		let adBlockEnabled = false
		const googleAdUrl = 'https://a.magsrv.com/ad-provider.js'
		try {
			await fetch(new Request(googleAdUrl))
		}
		catch (e) {
			adBlockEnabled = true
		}
		return adBlockEnabled
	}

	/**
	 * Retrieves the subdomain from the current window location.
	 * @returns The subdomain if it exists, otherwise null.
	 */
	public static getSubdomain(): string | null {
		const host = window.location.host
		const hostParts = host.split('.')
		return hostParts.length > 2 ? hostParts[0] : null
	}

	/**
	 * Converts a UTC date string to a local date string formatted according to the user's local time zone.
	 *
	 * This method takes a date string in UTC format and converts it to a local date and time string based on the user's
	 * current time zone settings. The returned date string is formatted in 'MM/dd/yyyy hh:mm a' format, where:
	 * - `MM` is the month in two digits.
	 * - `dd` is the day in two digits.
	 * - `yyyy` is the four-digit year.
	 * - `hh` is the hour in 12-hour format.
	 * - `mm` is the minutes in two digits.
	 * - `a` is the AM/PM marker.
	 *
	 * Example usage:
	 * ```typescript
	 * const utcDate = '2023-08-12T14:30:00Z';
	 * const localDate = Services.convertUTCDateToLocalDate(utcDate);
	 * console.log(localDate); // Outputs something like "08/12/2023 10:30 AM" depending on the user's time zone.
	 * ```
	 *
	 * @param dateString - A string representing a date in UTC format.
	 * @returns A string representing the local date and time formatted as 'MM/dd/yyyy hh:mm a'.
	 */
	public static convertUTCDateToLocalDate(dateString: string): string {
		const timeZone = Intl.DateTimeFormat(i18n.global.locale.value, { timeZoneName: 'longOffset' }).resolvedOptions().timeZone
		return formatInTimeZone(new Date(dateString), timeZone, t('date_format'))
	}

	/**
	 * Checks if all elements of one array are present in another array.
	 *
	 * If the length of `smallArray` is greater than the length of `largeArray`,
	 * the method immediately returns `false`.
	 *
	 * @param smallArray The array of elements to check.
	 * @param largeArray The array in which to check for the presence of elements.
	 * @returns True if all elements of `smallArray` are present in `largeArray`, otherwise false.
	 */
	public static isArrayInArray(smallArray: number[], largeArray: number[]): boolean {
		if (smallArray.length > largeArray.length) {
			return false
		}
		return smallArray.every(element => largeArray.includes(element))
	}

	/**
	 * Groups an array of items by a key generated from a provided function.
	 *
	 * This method iterates through an array of items and groups them into an object
	 * where the keys are generated by a callback function provided by the user. Each key
	 * in the resulting object corresponds to an array of items that share the same key.
	 *
	 * @template T The type of elements in the input array.
	 * @param {Array<T>} items - The array of items to group.
	 * @param {function(item: T): string | number} callbackFn - A function that takes an item and returns the key by which to group the items.
	 * @returns {Record<string, T[]>} An object where each key corresponds to a group, and the value is an array of items in that group.
	 *
	 * @example
	 * const fruits = [
	 *   { type: 'apple', color: 'red' },
	 *   { type: 'banana', color: 'yellow' },
	 *   { type: 'cherry', color: 'red' },
	 *   { type: 'grape', color: 'purple' }
	 * ];
	 *
	 * const groupedByColor = Tools.groupBy(fruits, item => item.color);
	 * // groupedByColor = {
	 * //   red: [
	 * //     { type: 'apple', color: 'red' },
	 * //     { type: 'cherry', color: 'red' }
	 * //   ],
	 * //   yellow: [{ type: 'banana', color: 'yellow' }],
	 * //   purple: [{ type: 'grape', color: 'purple' }]
	 * // }
	 */
	public static groupBy<T>(items: Array<T>, callbackFn: (item: T) => string | number): Record<string, T[]> {
		const result: Record<string, T[]> = {}

		items.forEach((item) => {
			const key = callbackFn(item)
			if (key in result) {
				result[key].push(item)
			}
			else {
				result[key] = [item]
			}
		})

		return result
	}

	/**
	 * Retrieves an item from localStorage. If the item does not exist, it is initialized with the provided default value.
	 *
	 * @param {string} key - The key of the item to retrieve from localStorage.
	 * @param {any} [defaultValue] - The default value to store if the item does not exist in localStorage.
	 * @returns {string} - The value of the item from localStorage, or the default value if the item does not exist.
	 */
	public static getLocalStorage(key: string, defaultValue: any = null): string {
		const item = localStorage.getItem(key)

		if (item === null) {
			if (typeof defaultValue !== 'string') {
				defaultValue = JSON.stringify(defaultValue)
			}

			localStorage.setItem(key, defaultValue)
			return defaultValue
		}

		try {
			const parsedItem = JSON.parse(item)
			return typeof parsedItem === 'object' ? JSON.stringify(parsedItem) : item
		}
		catch {
			return item
		}
	}

	/**
	 * Replaces an item in an array based on a condition provided by a callback function.
	 *
	 * This method finds the index of the first item in the array that satisfies the condition provided
	 * by the callback function. If an item is found, it is replaced with the new item. If no item is found,
	 * the array remains unchanged.
	 *
	 * @template T - The type of elements in the array.
	 * @param {T[]} array - The array in which to replace an item.
	 * @param {T} newItem - The new item to insert into the array.
	 * @param {function(item: T): boolean} cb - The callback function that tests each item. The first item for which the callback returns true will be replaced.
	 * @returns {boolean} - Returns true if the item was replaced, false if no matching item was found.
	 */
	public static replaceAtIndex<T>(array: T[], newItem: T, cb: (item: T) => boolean): boolean {
		const index = array.findIndex(cb)
		if (index !== -1) {
			array.splice(index, 1, newItem)
			return true
		}
		return false
	}

	/**
	 * Pauses execution for a specified duration in milliseconds.
	 *
	 * @param {number} durationMS - The duration to wait in milliseconds.
	 * @returns {Promise<void>} - A promise that resolves after the specified duration.
	 */
	public static async wait(durationMS: number): Promise<void> {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve()
			}, durationMS)
		})
	}

	/**
	 * Toggles the visibility of the scrollbar on the page.
	 *
	 * @param {boolean} show - Determines whether to show or hide the scrollbar.
	 *                         If true, the scrollbar is shown; if false, it is hidden.
	 *
	 * - When hiding the scrollbar, this function sets `overflow: hidden` on `document.body`
	 *   and adjusts `paddingRight` to prevent content shift.
	 * - When showing the scrollbar, it removes these styles, restoring normal scrolling.
	 */
	public static toggleScrollbar(show: boolean) {
		if (show) {
			document.body.style.overflow = ''
			document.body.style.paddingRight = ''
		}
		else {
			const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
			document.body.style.overflow = 'hidden'
			document.body.style.paddingRight = `${scrollbarWidth}px`
		}
	}

	/**
	 * Formats a large number into a readable string with suffixes (k, M, B).
	 *
	 * @param {number} num - The number to format.
	 * @returns {string} - The formatted number with a suffix:
	 *                     - 'B' for billions,
	 *                     - 'M' for millions,
	 *                     - 'k' for thousands,
	 *                     - or the original number if below 1,000.
	 *
	 * Examples:
	 * - 1_500 -> "1.5k"
	 * - 2_000_000 -> "2.0M"
	 * - 3_000_000_000 -> "3.0B"
	 */
	public static formatLargeNumber(num: number): string {
		if (num >= 1_000_000_000) {
			return `${(num / 1_000_000_000).toFixed(1)}B`
		}
		else if (num >= 1_000_000) {
			return `${(num / 1_000_000).toFixed(1)}M`
		}
		else if (num >= 1_000) {
			return `${(num / 1_000).toFixed(1)}k`
		}
		else {
			return num.toString()
		}
	}

	/**
	 * Formats a number with a specified separator for better readability.
	 *
	 * @param num The number to format.
	 * @param separator The separator to use (default: space).
	 * @returns The formatted number as a string.
	 *
	 * @example
	 * Tools.formatNumber(321321); // "321 321"
	 * Tools.formatNumber(1920959, ','); // "1,920,959"
	 */
	public static formatNumber(num: number, separator: string = ' '): string {
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator)
	}
}
