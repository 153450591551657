import { RequestAction } from '@/request/requestRouter'
import type { ApiRequest, RequestConfig, RequestRoutes } from '@/request/requestRouter'
import DeviceFingerprint from '@/utils/deviceFingerprint'
import Tools from '@/utils/tools'
import type { IUser, IUserDeviceAPI } from '@/types'
import disconnectUserMiddleware from '@/request/middlewares/disconnectUser'

const getUserDevices: ApiRequest<IUserDeviceAPI[]> = {
	action: RequestAction.GetUserDevices,
	endpoint: 'devices',
	config: {
		method: 'GET',
	},
	afterRequest: async (response: Response) => {
		const data = await response.json()

		if (data) {
			data.forEach((userDevice: IUserDeviceAPI) => {
				userDevice.last_seen = Tools.convertUTCDateToLocalDate(userDevice.last_seen)
			})
		}

		return data as IUserDeviceAPI[]
	},
}

const getUserSettings: ApiRequest<IUser | null> = {
	action: RequestAction.GetUserSettings,
	middlewares: [disconnectUserMiddleware],
	endpoint: 'me',
	config: {
		method: 'GET',
		credentials: 'include',
	},
	afterRequest: async (response: Response) => {
		if (response.ok) {
			return await response.json()
		}
		return null
	},
}

const deleteUserDevice: ApiRequest = {
	action: RequestAction.DeleteUserDevice,
	middlewares: [disconnectUserMiddleware],
	endpoint: 'device/{{deviceId}}',
	config: {
		method: 'DELETE',
		credentials: 'include',
	},
}

const updateUser: ApiRequest = {
	action: RequestAction.UpdateUser,
	middlewares: [disconnectUserMiddleware],
	endpoint: 'update',
	config: {
		method: 'POST',
		credentials: 'include',
	},
}

const login: ApiRequest = {
	action: RequestAction.Login,
	endpoint: 'login',
	config: {
		method: 'POST',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
		},
	},
	beforeRequest: async (config: RequestConfig): Promise<RequestConfig> => {
		config.headers = {
			...config.headers,
			'device-id': await DeviceFingerprint.create(),
		}
		return config
	},
}

const logout: ApiRequest = {
	action: RequestAction.Logout,
	middlewares: [disconnectUserMiddleware],
	endpoint: 'logout',
	config: {
		method: 'POST',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
		},
	},
}

const resetPassword: ApiRequest = {
	action: RequestAction.ResetPassword,
	endpoint: 'send_reset_pwd',
	config: {
		method: 'POST',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
		},
	},
}

const createUser: ApiRequest = {
	action: RequestAction.CreateUser,
	endpoint: 'create',
	config: {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
	},
	beforeRequest: async (config: RequestConfig): Promise<RequestConfig> => {
		config.headers = {
			...config.headers,
			'device-id': await DeviceFingerprint.create(),
		}
		return config
	},
}

export default {
	prefix: '/api/user',
	middlewares: [],
	queries: [getUserDevices, getUserSettings, deleteUserDevice, updateUser, login, logout, resetPassword, createUser],
} as RequestRoutes
