import { defineStore } from 'pinia'
import { computed, inject, nextTick, ref, watch } from 'vue'
import { addYears } from 'date-fns'
import { useSettingStore } from './settingStore'
import { useAuthStore } from './authStore'
import type { IFilter, IFilterAPI, IFilterOption, IFilterPreset, IGroupScene, IScene } from '@/types'
import Tools from '@/utils/tools'
import { RequestAction, type RequestRouter } from '@/request/requestRouter'

export const useFilterStore = defineStore('filter store', () => {
	const request = inject<RequestRouter>('request')

	const authStore = useAuthStore()
	const standardFilters = ref<IFilter[]>([])
	const sceneGroup = ref<IGroupScene[]>([])

	const selectedRender = ref<string>(Tools.getCookie('filters-render-value') ?? 'hentai')
	const selectedFilters = ref<Array<number[]>>([])
	const selectedGroups = ref<IGroupScene[]>([])
	const shuffle = ref<boolean>(true)

	const computedSelectedOptionsByFilter = computed(() => {
		const grouped = Tools.groupBy<number[]>(selectedFilters.value, ([filterId]) => filterId)

		const _groupedFilters: Record<string, number[]> = {}

		for (const filterId in grouped) {
			_groupedFilters[filterId] = grouped[filterId].map(([_, optionId]) => optionId)
		}

		return _groupedFilters
	})

	const computedSelectedFlatOptions = computed(() => {
		return [...new Set(Object.values(computedSelectedOptionsByFilter.value).flat())]
	})

	const computedSelectedFlatMetaOptions = computed(() => {
		return standardFilters.value
			.filter((filter: IFilter) => filter.meta)
			.flatMap((filter: IFilter) => filter.options.map(({ id }) => id))
			.filter(id => computedSelectedFlatOptions.value.includes(id))
	})

	const computedSelectedOptionsWithoutMeta = computed(() => {
		const selectedMetaOptionIds = computedSelectedFlatMetaOptions.value

		return selectedFilters.value.filter(([_, optionId]) =>
			!selectedMetaOptionIds.includes(optionId),
		)
	})

	const computedSelectedMetaOptions = computed(() => {
		return standardFilters.value
			.filter((filter: IFilter) => filter.meta)
			.flatMap((filter: IFilter) => filter.options.map(({ id }) => [filter.id, id]))
			.filter(([_, id]) => computedSelectedFlatOptions.value.includes(id))
	})

	const computedSelectedFlatGroups = computed(() => {
		return selectedGroups.value.flatMap(group => group.id)
	})

	const selectedScenes = computed(() => {
		return computedSelectedFlatGroups.value.reduce((acc: IScene[], sceneGroupId: number) => {
			const group = sceneGroup.value.find(({ id }) => id === sceneGroupId)
			if (group) {
				acc.push(...group.options)
			}
			return acc
		}, [])
	})

	const disabledScenes = ref<number[]>([])

	const grayOutOptions = computed(() => {
		const selectedMetaOptionIds = [...computedSelectedFlatMetaOptions.value]
		const selectedOptionIds = computedSelectedFlatOptions.value.filter((id: number) => !selectedMetaOptionIds.includes(id))

		// Currently, we gray out all options from scenes that don't contain all selected options.
		// However, an option remains available if another scene contains it.
		const splittedOptions: Record<string, Set<number>> = {
			available: new Set(),
			unavailable: new Set(),
		}

		// We need to handle meta filters differently, each scene must atleast match one meta ID, otherwise it will be pushed to unavailable
		selectedScenes.value.forEach((scene: IScene) => {
			const hasMetaFilter = scene.filters.some(id => selectedMetaOptionIds.includes(id))
			if (!hasMetaFilter && selectedMetaOptionIds.length) {
				scene.filters.forEach((optionId: number) => splittedOptions.unavailable.add(optionId))
			}
			else {
				const hasFilter = Tools.isArrayInArray(selectedOptionIds, scene.filters)
				scene.filters.forEach((optionId: number) => splittedOptions[hasFilter ? 'available' : 'unavailable'].add(optionId))
			}
		})

		return [...splittedOptions.unavailable].filter(optionId => !splittedOptions.available.has(optionId))
	})

	const grayOutGroups = computed(() => {
		const res: number[] = []
		disabledScenes.value = []

		if (computedSelectedOptionsWithoutMeta.value.length) {
			const flatted = computedSelectedOptionsWithoutMeta.value.map(([_, optionId]) => optionId)
			selectedGroups.value.forEach((group: IGroupScene) => {
				let matches = 0
				group.options.forEach((scene: IScene) => {
					const match = Tools.isArrayInArray(flatted, scene.filters)
					matches += match ? 1 : 0
					if (!match) {
						disabledScenes.value.push(scene.id)
					}
				})
				if (matches === 0) {
					res.push(group.id)
				}
			})
		}
		return res
	})

	const computedUniqueOptions = computed(() => {
		return [...new Set(selectedScenes.value.map((scene: IScene) => [...new Set(scene.filters)]).flat())]
	})

	const computedFilters = computed(() => {
		if (shuffle.value) {
			useSettingStore().randomizeSeed()
		}

		return {
			scene: selectedScenes.value.map(({ id }) => id).filter((id: number) => !disabledScenes.value.includes(id)),
			filter: computedSelectedOptionsByFilter.value,
			shuffle: shuffle.value,
			render: selectedRender.value,
		} as IFilterAPI
	})

	const computedAvailableFilters = computed((): IFilter[] => {
		return standardFilters.value
			.map((filter: IFilter) => {
				const matchedOptions = filter.options.filter(({ id }) => computedUniqueOptions.value.includes(id))
				return matchedOptions.length === filter.options.length
					? filter
					: matchedOptions.length
						? { ...filter, options: matchedOptions }
						: null
			})
			.filter((filter): filter is IFilter => filter !== null)
			.sort((a: IFilter, b: IFilter) => (b.meta ? 1 : 0) - (a.meta ? 1 : 0))
	})

	async function init(): Promise<void> {
		standardFilters.value = await request?.exec(RequestAction.GetFiltersConfig, { routeParams: { render: selectedRender.value } }) ?? []
		sceneGroup.value = await request?.exec(RequestAction.GetSceneFiltersConfig, { routeParams: { render: selectedRender.value } }) ?? []
		selectedGroups.value = [...sceneGroup.value.filter(group => !group.premium)]
	}

	let _loadFromPreset: (() => void) | null = null

	const loadFromPreset = (preset: IFilterPreset) => {
		_loadFromPreset = () => {
			const _selectedFilters: Array<number[]> = []
			const _selectedGroups: IGroupScene[] = []
			for (const filterId in preset.filters) {
				preset.filters[filterId].forEach((optionId: number) => _selectedFilters.push([Number(filterId), optionId]))
			}

			preset.groups.forEach((groupId: number) => {
				const group = sceneGroup.value.find(({ id }) => groupId === id)
				if (group) {
					_selectedGroups.push(group)
				}
			})
			selectedFilters.value = _selectedFilters
			selectedGroups.value = _selectedGroups
		}
		if (preset.render === selectedRender.value) {
			_loadFromPreset()
			_loadFromPreset = null
		}
		else {
			selectedRender.value = preset.render
		}
	}

	watch(() => selectedRender.value, async () => {
		await init()
		if (_loadFromPreset !== null) {
			_loadFromPreset()
			_loadFromPreset = null
		}
		else {
			selectedFilters.value = []
		}
		Tools.setCookie('filters-render-value', selectedRender.value, addYears(new Date(), 1), `.${import.meta.env.VITE_DOMAIN}`, '/')
	})

	const toggleFilter = (filterId: number, optionId: number): void => {
		const index = selectedFilters.value.findIndex(([_, _optionId]) => optionId === _optionId)

		if (index === -1) {
			selectedFilters.value.push([filterId, optionId])
		}
		else {
			selectedFilters.value.splice(index, 1)
		}
	}

	const toggleMetaFilter = (filterId: number, optionId: number): void => {
		const index = selectedFilters.value.findIndex(([_, _optionId]) => optionId === _optionId)

		if (index === -1) {
			selectedFilters.value.push([filterId, optionId])
		}
		else {
			selectedFilters.value.splice(index, 1)
		}

		const meta: number[][] = computedSelectedMetaOptions.value

		const tmp = computedSelectedOptionsWithoutMeta.value
		selectedFilters.value = []
		meta.forEach(([metaFilterId, metaOptionId]) => {
			toggleFilter(metaFilterId, metaOptionId)
		})
		tmp.forEach(([filterId, optionId]) => {
			if (!grayOutOptions.value.includes(optionId)) {
				toggleFilter(filterId, optionId)
			}
		})
	}

	const toggleSceneGroup = (sceneGroupId: number) => {
		const group: IGroupScene | undefined = selectedGroups.value.find(({ id }) => id === sceneGroupId)

		if (group) {
			selectedGroups.value = selectedGroups.value.filter(({ id }) => id !== sceneGroupId)

			const remainingOptions = [...new Set(selectedScenes.value.map(({ filters }) => filters).flat())]

			for (let i = 0; i < selectedFilters.value.length; i++) {
				const optionId = selectedFilters.value[i][1]

				if (!remainingOptions.includes(optionId)) {
					selectedFilters.value.splice(i, 1)
					i--
				}
			}
			// redo the selection
			const tmp = selectedFilters.value
			selectedFilters.value = []
			tmp.forEach(([filterId, optionId]) => {
				if (!grayOutOptions.value.includes(optionId)) {
					toggleFilter(filterId, optionId)
				}
			})
		}
		else {
			const groupToInsert = sceneGroup.value.find(({ id }) => id === sceneGroupId)
			if (groupToInsert) {
				selectedGroups.value.push(groupToInsert)
			}
		}
	}

	nextTick().then(() => {
		authStore.addLogoutCallback(() => {
			// disable premium filter option on logout
			computedAvailableFilters.value.forEach((filter: IFilter) => {
				filter.options.forEach((option: IFilterOption) => {
					if (option.premium && selectedFilters.value.find(([_, optionId]) => optionId === option.id)) {
						toggleFilter(filter.id, option.id)
					}
				})
			})
		})
		authStore.addLogoutCallback(() => {
			// disable premium groups on logout
			sceneGroup.value.forEach((group: IGroupScene) => {
				const index = selectedGroups.value.findIndex(({ id }) => group.id === id)
				if (group.premium && index >= 0) {
					selectedGroups.value.splice(index, 1)
				}
			})
		})
	})

	function clearFilters() {
		if (selectedFilters.value.length) {
			selectedFilters.value = []
		}
	}

	return {
		init,
		toggleSceneGroup,
		toggleFilter,
		toggleMetaFilter,
		loadFromPreset,
		clearFilters,
		shuffle,
		selectedRender,
		selectedFilters,
		selectedGroups,
		grayOutOptions,
		grayOutGroups,
		standardFilters,
		sceneGroup,
		computedFilters,
		computedAvailableFilters,
		computedSelectedOptionsByFilter,
		computedSelectedFlatGroups,
		computedSelectedFlatOptions,
		computedSelectedFlatMetaOptions,
	}
})
