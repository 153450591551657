import { defineStore, storeToRefs } from 'pinia'
import { computed, inject, ref } from 'vue'
import type { IItemRewardTier, IPlayer } from '@/types'
import { RequestAction, type RequestRouter } from '@/request/requestRouter'
import { IPlayerFactory } from '@/types/factory'
import { useAudioStore } from '@/stores/audioStore'
import { useNotificationStore } from '@/stores/notificationStore'

export const usePlayerStore = defineStore('playerStore', () => {
	const request = inject<RequestRouter>('request')
	const notifStore = useNotificationStore()
	const audioStore = useAudioStore()

	const player = ref<IPlayer>(IPlayerFactory())
	const claimDone = ref<boolean>(true)

	const setPlayer = () => {
		return request?.exec(RequestAction.GetUserPlayer)
			.then((_player: IPlayer | null) => {
				if (_player) {
					player.value = _player
				}
				else {
					console.error('No player found:', _player)
				}
			})
			.catch((error) => {
				console.error('Error fetching player:', error)
			})
	}

	const requiredXp = computed(() => {
		const threshold = 6
		const multiplier = 1.3 + Math.floor(player.value.level / threshold) / 10
		return Math.floor(player.value.level * 100 * multiplier)
	})

	const progress = computed(() => {
		const percentage = (player.value.xp_progress / requiredXp.value) * 100
		return `${Math.floor(percentage)}%`
	})

	const baseWeights: Record<IItemRewardTier, number> = { S: 0.0, A: 0.2, B: 0.5, C: 0.7 }
	const invertedWeights: Record<IItemRewardTier, number> = { S: 0.7, A: 0.5, B: 0.2, C: 0.0 }
	const maxPlayerLevel = 100

	const procChances = computed((): Record<IItemRewardTier, number> => {
		const playerLevel = Math.min(player.value.level, maxPlayerLevel)
		const progressionFactor = playerLevel / maxPlayerLevel

		const calculateWeight = (tier: IItemRewardTier) => {
			const baseWeight = baseWeights[tier] ?? 0.0
			const invertedWeight = invertedWeights[tier] ?? 1.0
			return baseWeight + (invertedWeight - baseWeight) * progressionFactor
		}

		return {
			S: calculateWeight('S'),
			A: calculateWeight('A'),
			B: calculateWeight('B'),
			C: calculateWeight('C'),
		}
	})

	const addXp = (xp: number): void => {
		player.value.xp_progress += xp
		if (xp > 0) {
			audioStore.play('xp_fill')
		}
		while (player.value.xp_progress >= requiredXp.value) {
			player.value.xp_progress -= requiredXp.value
			player.value.level++
			audioStore.play('level_up')
		}
	}

	const claimRewards = () => {
		if (notifStore.countNewRewards) {
			notifStore.countNewRewards = 0
			claimDone.value = false
		}
	}

	return {
		procChances,
		player,
		setPlayer,
		progress,
		addXp,
		requiredXp,
		claimDone,
		claimRewards,
	}
})
