import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'
import { CLocales } from '@/constants'
import type { ILocale, ISettingStore } from '@/types'
import { EImageGalleryType } from '@/types'
import { i18n } from '@/i18n'
import Tools from '@/utils/tools'

export const useSettingStore = defineStore('settingStore', () => {
	const cookieLocale = Tools.getCookie('lang')
	const galleryType = ref<EImageGalleryType>(EImageGalleryType.Gallery)
	const imageFullScreen = ref<boolean>(false)
	const galleryScroll = ref<number>(0)
	const galleryEventScroll = ref<number>(0)
	const favoriteScroll = ref<number>(0)
	const isBot = ref<boolean>(/bot|InspectionTool|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent))

	const restoreScroll = ref<() => void>(() => {})

	const settings = reactive<ISettingStore>({
		locale: {
			name: 'English',
			code: 'en',
		},
	})

	const setLocale = (newLocale: ILocale): void => {
		i18n.global.locale.value = newLocale.code
		settings.locale = { ...newLocale }
	}

	const redirectTo = (locale: string) => {
		if (!isBot.value) {
			window.location.replace(`${import.meta.env.VITE_PROTOCOL}://${locale}.${import.meta.env.VITE_DOMAIN}${window.location.pathname}${window.location.search}`)
		}
	}
	const subdomain = Tools.getSubdomain()

	if (cookieLocale && subdomain !== cookieLocale) {
		setLocale(CLocales.find((cLocale: ILocale) => cLocale.code === cookieLocale) || settings.locale)
		redirectTo(cookieLocale)
	}
	else {
		setLocale(CLocales.find((cLocale: ILocale) => cLocale.code === cookieLocale) || settings.locale)
	}

	if (!cookieLocale) {
		if (!subdomain) {
			let localeFound = false
			CLocales.forEach((cLocale: ILocale) => {
				if (navigator.language.includes(cLocale.code)) {
					localeFound = true
					setLocale(cLocale)
					redirectTo(cLocale.code)
				}
			})
			if (!localeFound) {
				setLocale(CLocales[1])
			}
		}
		else {
			const locale = CLocales.find((cLocale: ILocale) => cLocale.code === subdomain) || settings.locale
			setLocale(locale)
		}
	}

	const getLocale = (): ILocale => {
		return settings.locale
	}

	/**
	 * Seed handler, used to randomize gallery.
	 */

	const seed = ref<number>(Tools.random(-100000, 100000) / 100000)
	const timestamp = ref<string>(new Date().toISOString())

	const randomizeSeed = (): void => {
		seed.value = Tools.random(-100000, 100000) / 100000
	}

	const resetTimestamp = (): void => {
		timestamp.value = new Date().toISOString()
	}

	return { isBot, restoreScroll, galleryEventScroll, galleryScroll, favoriteScroll, settings, seed, timestamp, setLocale, getLocale, galleryType, imageFullScreen, randomizeSeed, resetTimestamp }
})
