import { RequestRouter } from '@/request/requestRouter'
import routeActions from '@/request/routes/actions'
import routeItems from '@/request/routes/rewards'
import routePlayers from '@/request/routes/player'
import routeConfigs from '@/request/routes/config'
import routePreset from '@/request/routes/preset'
import routeImage from '@/request/routes/image'
import routeOrders from '@/request/routes/orders'
import routeAchievements from '@/request/routes/achievements'
import routeNotification from '@/request/routes/notification'
import routeToken from '@/request/routes/token'
import routePublic from '@/request/routes/public'
import routeGallery from '@/request/routes/gallery'
import routeUser from '@/request/routes/user'
import routeLeaderboard from '@/request/routes/leaderboard'
import routeEvent from '@/request/routes/event'
import routeCount from '@/request/routes/count'

const service = new RequestRouter()

const routes = [
	routeUser,
	routeActions,
	routePlayers,
	routeConfigs,
	routePreset,
	routeImage,
	routeOrders,
	routeAchievements,
	routeNotification,
	routeToken,
	routePublic,
	routeItems,
	routeGallery,
	routeLeaderboard,
	routeEvent,
	routeCount,
]

routes.forEach(route => service.add(route))

export default service
