import type { ComputedRef, Ref } from 'vue'
import { computed, onMounted, onUnmounted, ref } from 'vue'

export interface IResizeListener {
	screenWidth: Ref<number>
	computedScreenWidth: ComputedRef<number>
	computedScreenHeight: ComputedRef<number>
}

export function useResizeListener(): IResizeListener {
	const screenWidth = ref(window.innerWidth)
	const screenHeight = ref(window.innerHeight)

	const computedScreenWidth = computed(() => screenWidth.value)
	const computedScreenHeight = computed(() => screenHeight.value)

	const update = () => {
		screenWidth.value = window.innerWidth
		screenHeight.value = window.innerHeight
	}

	onMounted(() => {
		window.addEventListener('resize', update)
	})

	onUnmounted(() => {
		window.removeEventListener('resize', update)
	})

	return { screenWidth, computedScreenWidth, computedScreenHeight }
}
