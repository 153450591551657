<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import { computed } from 'vue'
import { Bot, Mouse, PartyPopper, TentTree, Zap } from 'lucide-vue-next'

const { t } = useI18n()

const formattedMainTitle = computed(() => {
	const highlight1 = `<span class="text-purple-500 font-extrabold">${t('about_page.bold.item11')}</span>`
	return t('about_page.title1', { highlight1 })
})

const formattedParagraph1 = computed(() => {
	const bold1 = `<span class="font-bold">${t('about_page.bold.item1')}</span>`
	const bold2 = `<span class="font-bold">${t('about_page.bold.item3')}</span>`
	const bold3 = `<span class="font-bold">${t('about_page.bold.item6')}</span>`

	return t('about_page.paragraph1', { bold1, bold2, bold3 })
})

const formattedContinuousStreamTitle = computed(() => {
	const highlight1 = `<span class="text-purple-500 font-extrabold">${t('about_page.bold.item12')}</span>`
	return t('about_page.title2', { highlight1 })
})

const formattedFiltersTitle = computed(() => {
	const highlight1 = `<span class="text-purple-500 font-extrabold">${t('about_page.bold.item13')}</span>`
	return t('about_page.title3', { highlight1 })
})

const formattedContinuousStreamParagraph = computed(() => {
	const bold1 = `<span class="font-bold">${t('about_page.bold.item2')}</span>`
	const bold2 = `<span class="font-bold">${t('about_page.bold.item4')}</span>`
	const bold3 = `<span class="font-bold">${t('about_page.bold.item7')}</span>`

	return t('about_page.paragraph2', { bold1, bold2, bold3 })
})

const formattedFiltersParagraph = computed(() => {
	const bold1 = `<span class="font-bold">${t('about_page.bold.item14')}</span>`
	const bold2 = `<span class="font-bold">${t('about_page.bold.item5')}</span>`
	const bold3 = `<span class="font-bold">${t('about_page.bold.item15')}</span>`
	const bold4 = `<span class="font-bold">${t('about_page.bold.item4')}</span>`
	return t('about_page.paragraph3', { bold1, bold2, bold3, bold4 })
})

const formattedBetaTitle = computed(() => {
	const highlight1 = `<span class="text-purple-500 font-extrabold">${t('about_page.bold.item16')}</span>`
	return t('about_page.title4', { highlight1 })
})

const formattedBetaParagraph = computed(() => {
	const bold1 = `<span class="font-bold">${t('about_page.bold.item8')}</span>`
	const bold2 = `<span class="font-bold">${t('about_page.bold.item9')}</span>`
	const bold3 = `<span class="font-bold">${t('about_page.bold.item10')}</span>`
	return t('about_page.paragraph4', { bold1, bold2, bold3 })
})

const formattedAICreatedTitle = computed(() => {
	const highlight1 = `<span class="text-purple-500 font-extrabold">${t('about_page.bold.item17')}</span>`
	return t('about_page.title5', { highlight1 })
})

const formattedAICreatedParagraph = computed(() => {
	const bold1 = `<span class="font-bold">${t('about_page.bold.item2')}</span>`
	const bold2 = `<span class="font-bold">${t('about_page.bold.item3')}</span>`
	return t('about_page.paragraph5', { bold1, bold2 })
})
</script>

<template>
	<div class="flex w-screen justify-center mb-[50px]">
		<section class="flex items-center flex-col w-full px-4 gap-4 mt-[75px] h-fit odd:[&>article]:bg-gray-800 even:[&>article]:bg-gray-900">
			<article class="block">
				<div class="flex items-center">
					<h2 class="block-title" v-html="formattedMainTitle" />
				</div>
				<p
					class="block-paragraph"
					v-html="formattedParagraph1"
				/>
			</article>
			<article class="block">
				<div class="flex items-center">
					<Mouse class="block-title-icon" />
					<h2 class="block-title" v-html="formattedContinuousStreamTitle" />
				</div>

				<p
					class="block-paragraph"
					v-html="formattedContinuousStreamParagraph"
				/>
			</article>
			<article class="block">
				<div class="flex items-center">
					<Zap class="block-title-icon" />
					<h2 class="block-title" v-html="formattedFiltersTitle" />
				</div>

				<p
					class="block-paragraph"
					v-html="formattedFiltersParagraph"
				/>
			</article>
			<article class="block">
				<div class="flex items-center">
					<PartyPopper class="block-title-icon" />
					<h2 class="block-title" v-html="formattedBetaTitle" />
				</div>

				<p
					class="block-paragraph"
					v-html="formattedBetaParagraph"
				/>
			</article>
			<article class="block">
				<div class="flex items-center">
					<Bot class="block-title-icon" />
					<h2 class="block-title" v-html="formattedAICreatedTitle" />
				</div>

				<p
					class="block-paragraph"
					v-html="formattedAICreatedParagraph"
				/>
			</article>
			<router-link to="/" class="no-underline mt-10">
				<Button type="submit" :label="t('about_page.button')" class="w-fit px-8 flex gap-2 !text-lg md:!text-xl" outlined>
					<template #icon>
						<TentTree class="w-5 h-5 md:w-6 md:h-6" />
					</template>
				</Button>
			</router-link>
		</section>
	</div>
</template>

<style scoped lang="scss">
.block {
	@apply flex w-full p-5 rounded flex-wrap flex-col justify-center gap-3 min-h-[calc(100vh-90px)];
}
.block-paragraph {
	@apply break-words text-base md:text-lg border-solid border-4 border-y-0 border-purple-500 border-e-0 pl-2;
}
.block-title-icon {
	@apply mr-2 w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10;
}
.block-title {
	@apply m-0 text-3xl md:text-4xl lg:text-5xl;
}
</style>
