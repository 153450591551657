import type { IUser } from '@/types/user'

export function IUserFactory(data: Partial<IUser> = {}): IUser {
	return {
		id: 0,
		username: '',
		email: '',
		premium: {
			active: false,
			expiration_date: '',
		},
		keep_updated: false,
		lang: 'en',
		...data,
	}
}
