import { defineStore } from 'pinia'
import { computed, inject, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { addSeconds } from 'date-fns'
import { Swords } from 'lucide-vue-next'
import { useAuthStore } from './authStore'
import type { IBundleNotification, IImage } from '@/types'
import { ENotificationType } from '@/types'
import { RequestAction, type RequestRouter } from '@/request/requestRouter'
import Tools from '@/utils/tools'
import { useNotificationStore } from '@/stores/notificationStore'
import FapFapError, { WaitForEventError } from '@/errors/FapFapError'

export const useEventGalleryStore = defineStore('event gallery images', () => {
	const notifStore = useNotificationStore()
	const request = inject<RequestRouter>('request')
	const route = useRoute()
	const router = useRouter()
	const images = ref<IImage[]>([])
	const index = ref<number>(-1)
	const expiresIn = ref<number>(360)
	const uuid = ref<string>('')

	const heartbeat = 5000
	let pollingInProgress = false

	notifStore.createNotificationDelayer(ENotificationType.Bundle, () => {
		return route.path.includes('/event')
	})

	const isVisitor = computed(() => {
		const legalAge = Tools.getCookie('age-verification') === 'true'
		const consent = Tools.getCookie('cookie-consent')
		const isAuth = useAuthStore().isAuth()
		return !!legalAge && !!consent && !isAuth
	})

	const clear = () => {
		notifStore.dispatchDelayedNotification(ENotificationType.Bundle)
		images.value = []
		index.value = -1
	}

	const startPolling = () => {
		if (pollingInProgress) {
			return
		}
		pollingInProgress = true
		setTimeout(async () => {
			const requestType: RequestAction = isVisitor.value ? RequestAction.GetVisitorEvent : RequestAction.GetEvent

			const res = await request?.exec(requestType)
			const paused = Tools.getCookie('pause_events') !== null
			if (res) {
				const event = await res.json()

				uuid.value = event?.data?.uuid ?? 'visitor'
				if ('detail' in event) {
					expiresIn.value = event.detail?.expires_in ?? 360
				}
				else if ('data' in event) {
					expiresIn.value = event.data?.expires_in ?? 360
				}
				else {
					expiresIn.value = 360
				}

				let delay = isVisitor.value ? 60 * 10 : (expiresIn.value + 1)
				if (route.path.includes('/event')) {
					delay = 10
				}
				if (res.ok) {
					if (!route.path.includes('/event') && !paused) {
						notifStore.push(ENotificationType.AppEvent, {
							icon: Swords,
							titleColor: 'text-yellow-300',
							iconColor: 'text-yellow-300',
							delay: delay * 1000,
							expires_at: addSeconds(new Date(), delay),
							action: () => {
								clear()
								if (isVisitor.value) {
									images.value = event.items
								}
								router.push(`/event/${uuid.value}`)
							},
						} as IBundleNotification)
					}
					if (!isVisitor.value) {
						setTimeout(() => {
							pollingInProgress = false
							startPolling()
						}, delay * 1000)
					}
				}
				else {
					const eventError = FapFapError.auto(event.detail.code)
					if (eventError instanceof WaitForEventError) {
						setTimeout(() => {
							pollingInProgress = false
							startPolling()
						}, delay * 1000)
						pollingInProgress = false
					}
				}
			}
		}, heartbeat)
	}

	startPolling()

	watch(
		() => route.fullPath,
		() => {
			if (route.name !== 'Image' && route.name !== 'Event Gallery') {
				clear()
			}
		},
	)

	const load = async (uuid: string) => {
		const data = await request?.exec(RequestAction.ConsumeEvent, {
			routeParams: {
				uuid,
			},
		})
		if (data) {
			images.value = data.items
		}
	}

	const setZoomedImage = async (_index: number) => {
		if (_index < 0) {
			throw new Error('Index .')
		}
		else {
			index.value = _index
		}
	}

	const updateImage = (uuid: string, content: Partial<IImage>): void => {
		const i = images.value.findIndex(image => image.uuid === uuid)

		if (i >= 0) {
			images.value[i] = {
				...images.value[i],
				...content,
			}
		}
	}

	const getImage = (uuid: string): IImage | null => {
		const i = images.value.findIndex(image => image.uuid === uuid)

		return i >= 0 ? images.value[i] : null
	}

	return {
		images,
		index,
		isVisitor,
		load,
		setZoomedImage,
		updateImage,
		getImage,
		clear,
		startPolling,
	}
})
