import { RequestAction } from '@/request/requestRouter'
import type { ApiRequest, RequestRoutes } from '@/request/requestRouter'
import type { IFilterPreset } from '@/types'
import disconnectUserMiddleware from '@/request/middlewares/disconnectUser'

const saveFilterPreset: ApiRequest<IFilterPreset> = {
	action: RequestAction.SaveFilterPreset,
	middlewares: [disconnectUserMiddleware],
	endpoint: '',
	config: {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
	},
	afterRequest: async (response: Response) => await response.json(),
}

const getFilterPresets: ApiRequest<IFilterPreset[]> = {
	action: RequestAction.GetFilterPresets,
	endpoint: '',
	config: {
		method: 'GET',
	},
	afterRequest: async (response: Response) => await response.json(),
}

const updateFilterPreset: ApiRequest = {
	action: RequestAction.UpdateFilterPreset,
	middlewares: [disconnectUserMiddleware],
	endpoint: '',
	config: {
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/json',
		},
	},
}

const deleteFilterPreset: ApiRequest = {
	action: RequestAction.DeleteFilterPreset,
	middlewares: [disconnectUserMiddleware],
	endpoint: '{{id}}',
	config: {
		method: 'DELETE',
	},
}

const getPublicPreset: ApiRequest<IFilterPreset[]> = {
	action: RequestAction.GetPublicPreset,
	middlewares: [disconnectUserMiddleware],
	endpoint: 'public/{{userId}}',
	config: {
		method: 'GET',
	},
	afterRequest: async (response: Response) => await response.json(),
}

const setPresetVisibility: ApiRequest = {
	action: RequestAction.SetPresetVisibility,
	middlewares: [disconnectUserMiddleware],
	endpoint: '{{visibility|bool(public,private)}}/{{presetId}}',
	config: {
		method: 'PATCH',
	},
}

const importPreset: ApiRequest = {
	action: RequestAction.ImportPreset,
	middlewares: [disconnectUserMiddleware],
	endpoint: 'import/{{presetId}}',
	config: {
		method: 'PUT',
	},
}

export default {
	prefix: '/api/preset',
	middlewares: [],
	queries: [saveFilterPreset, getFilterPresets, updateFilterPreset, deleteFilterPreset, deleteFilterPreset, getPublicPreset, setPresetVisibility, importPreset],
} as RequestRoutes
