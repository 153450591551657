import { IEquippedItemsFactory } from './itemReward'
import type { IPlayer, IProfile } from '@/types/player'

export function IPlayerFactory(data: Partial<IPlayer> = {}): IPlayer {
	return {
		id: 0,
		approved_reports: 0,
		daily_streak: 0,
		daily_streak_best: 0,
		level: 1,
		scroll: 0,
		user_id: 0,
		xp_progress: 0,
		total_fav: 0,
		equipped: IEquippedItemsFactory(),
		...data,
	} as IPlayer
}

export function IProfileFactory(data: Partial<IProfile> = {}): IProfile {
	return {
		id: data.id ?? 0,
		player: IPlayerFactory(data.player ?? {}),
		username: data.username ?? '',
	}
}
