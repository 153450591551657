import type { ApiRequest, RequestRoutes } from '@/request/requestRouter'
import { RequestAction } from '@/request/requestRouter'
import type { IMembershipTierAPI, IUserMembershipAPI } from '@/types'
import Tools from '@/utils/tools'
import disconnectUserMiddleware from '@/request/middlewares/disconnectUser'

const getMembershipTiers: ApiRequest<IMembershipTierAPI[]> = {
	action: RequestAction.GetMembershipTiers,
	endpoint: 'membership/tiers',
	config: {
		method: 'GET',
	},
	afterRequest: async (response: Response) => {
		const data = await response.json()
		return data as IMembershipTierAPI[]
	},
}

const placePaypalOrder: ApiRequest<any> = {
	action: RequestAction.PlacePaypalOrder,
	middlewares: [disconnectUserMiddleware],
	endpoint: '',
	config: {
		method: 'POST',
	},
	afterRequest: async (response: Response) => {
		if (!response.ok) {
			throw new Error(`Failed to place paypal order: ${response.status} ${response.statusText}`)
		}
		return await response.json()
	},
}

const completePaypalOrder: ApiRequest<any> = {
	action: RequestAction.CompletePaypalOrder,
	middlewares: [disconnectUserMiddleware],
	endpoint: '{{orderId}}/capture',
	config: {
		method: 'POST',
	},
	afterRequest: async (response: Response) => {
		if (!response.ok) {
			throw new Error(`Failed to complete paypal order: ${response.status} ${response.statusText}`)
		}
		return await response.json()
	},
}

const getOrders: ApiRequest<IUserMembershipAPI[]> = {
	action: RequestAction.GetOrders,
	middlewares: [disconnectUserMiddleware],
	endpoint: 'history',
	config: {
		method: 'GET',
	},
	afterRequest: async (response: Response) => {
		const data = await response.json()
		if (data) {
			data.forEach((purchase: IUserMembershipAPI) => {
				purchase.created_at = Tools.convertUTCDateToLocalDate(purchase.created_at)
				purchase.end_at = Tools.convertUTCDateToLocalDate(purchase.end_at)
			})
		}
		return data as IUserMembershipAPI[]
	},
}

export default {
	prefix: '/api/orders',
	middlewares: [],
	queries: [getMembershipTiers, placePaypalOrder, completePaypalOrder, getOrders],
} as RequestRoutes
