<script setup lang="ts">
import { onBeforeUpdate, onUpdated, ref, watch, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import Tools from '@/utils/tools'

interface Props {
	reveal: boolean
}

const props = defineProps<Props>()

const emit = defineEmits<{
	(event: 'revealed', value: void): void
}>()

const { t } = useI18n()

const cellX = 16
const cellY = 22

const totalCells = cellX * cellY

function shuffleArray(array: number[]) {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Tools.random(0, i);
		[array[i], array[j]] = [array[j], array[i]]
	}
	return array
}

const click = ref<boolean>(false)

const cells = ref<Array<HTMLElement>>([])

function setItemRef(el: any) {
	if (el) {
		cells.value.push(el)
	}
}

onBeforeUpdate(() => {
	cells.value = []
})

const executed = ref<number>(0)
const animate = ref<boolean>(true)

onUpdated(() => {
	const indices = shuffleArray(Array.from({ length: cells.value.length }, (_, i) => i))

	for (let i = 0; i < indices.length; i++) {
		const delay = Tools.random(100, 1000)
		setTimeout(() => {
			if (cells.value[indices[i]]) {
				cells.value[indices[i]].classList.add('anim')
			}
			setTimeout(() => {
				executed.value++
			}, 300)
		}, delay)
	}
})

watchEffect(() => {
	if (executed.value === cells.value.length && cells.value.length > 0) {
		animate.value = false
		cells.value = []
		emit('revealed')
	}
})

watch(() => props.reveal, () => {
	if (props.reveal) {
		click.value = true
	}
}, { immediate: true })
</script>

<template>
	<div class="relative cursor-pointer">
		<div v-if="click && animate" class="absolute w-full h-full z-[7] flex flex-wrap gap-0">
			<div
				v-for="cell in totalCells"
				:ref="setItemRef"
				:key="cell" :style="{
					background: `rgb(31 41 55)`,
					width: `${100 / cellX}%`,
				}"
				class="cell"
			/>
		</div>
		<div v-else-if="animate" class="absolute w-full h-full z-[7] flex flex-wrap gap-0 bg-gray-800 rounded flex-center text-gray-300" @click="click = true">
			{{ t('event.reveal') }}
		</div>
		<slot />
	</div>
</template>

<style scoped>
@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.anim {
    animation: fade-out 0.3s ease-in-out 0s 1 forwards;
}
</style>
