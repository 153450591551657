import { RequestAction } from '@/request/requestRouter'
import type { ApiRequest, RequestRoutes } from '@/request/requestRouter'
import type { IBaseLeaderboardUser, ILeaderboardUserStat, IPage } from '@/types'

const getLeaderboard: ApiRequest<IPage<ILeaderboardUserStat>> = {
	action: RequestAction.GetLeaderboard,
	endpoint: '{{leaderboard}}?page={{page}}&size={{size}}', // 'scroll', 'level', 'daily', 'report'
	config: {
		method: 'POST',
	},
	afterRequest: async (response: Response) => {
		const data = await response.json()
		if (response.headers.has('x-current-player-rank')) {
			const userRank = response.headers.get('x-current-player-rank')
			if (userRank) {
				data.items_length = data.items.length
				data.items.splice(0, 0, { ...data.items[0], rank: Number(userRank) })
			}
		}
		return data as IPage<ILeaderboardUserStat>
	},
}

export default {
	prefix: '/api/leaderboard',
	middlewares: [],
	queries: [getLeaderboard],
} as RequestRoutes
