import FapFapError, { DeviceError } from '@/errors/FapFapError'

export default async function disconnectUserMiddleware(response: Response): Promise<boolean> {
	if (!response.ok) {
		const clone = response.clone()
		const data = await clone.json()
		const deviceError = FapFapError.auto(data.detail.code)
		if (deviceError instanceof DeviceError) {
			window.location.reload()
			return false
		}
	}
	return true
}
