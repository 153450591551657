import type { App } from 'vue'
import type { RouteLocationNormalized, Router } from 'vue-router'
import { i18n } from '@/i18n'
/* import { useFilterStore } from '@/stores/filterStore'
 */
const { t } = i18n.global

export default {
	install(app: App, options: any, router: Router) {
	/* 	const filterStore = useFilterStore()
		const { scenes, selectedScene } = storeToRefs(filterStore) */

		const createMetaTag = (name: string, content: string, route: RouteLocationNormalized, isOgTag: boolean = false): void => {
			const tag = document.createElement('meta')
			const match: RegExpMatchArray | null = content.match(/::(.*?)::/g)

			tag.setAttribute(isOgTag ? 'property' : 'name', name)

			if (content.startsWith('@')) {
				tag.setAttribute('content', t(content.slice(1)))
			}
			else if (match) {
				const replacedContent = content.replace(/::(.*?)::/g, (fullMatch: string, key: string): string => {
					return route.params[key] as string || fullMatch
				})
				tag.setAttribute('content', replacedContent)
			}
			else {
				tag.setAttribute('content', content)
			}

			document.head.prepend(tag)
		}

		const setMetaAttributes = (meta: any, route: RouteLocationNormalized) => {
			if (!meta) {
				return
			}

			if (meta.title.startsWith('@')) {
				const scene: string = ''
				let render: string = ''

				/* 				if (typeof route.query.scene === 'string' && scenes.value.length) {
					scene = Tools.capitalizeFirstLetter(scenes.value[selectedScene.value].lib)
				} */
				if (typeof route.query.render === 'string') {
					render = t(`render.${route.query.render}`)
				}
				document.title = t(meta.title.slice(1), { scene, render })
			}
			else {
				document.title = meta.title || 'FapFap.ai'
			}

			if (meta.title) {
				createMetaTag('title', meta.title, route)
			}

			if (meta.description) {
				createMetaTag('description', meta.description, route)
			}

			if (meta.keywords) {
				createMetaTag('keywords', (meta.keywords as string[]).join(', '), route)
			}

			if (meta.metaTags) {
				for (const [property, content] of Object.entries(meta.metaTags)) {
					if (property.startsWith('og:')) {
						createMetaTag(property, content as string, route, true)
						if (property === 'og:title') {
							createMetaTag('twitter:title', content as string, route)
						}
						if (property === 'og:description') {
							createMetaTag('twitter:description', content as string, route)
						}
						if (property === 'og:image') {
							createMetaTag('twitter:image', content as string, route)
						}
						continue
					}
					if (property.startsWith('twitter:')) {
						createMetaTag(property, content as string, route)
					}
				}
			}
		}

		const removeMetaTag = (property: string, isOgTag: boolean = false) => {
			const name = isOgTag ? 'property' : 'name'

			const tag = document.querySelector(`meta[${name}="${property}"]`)
			tag?.remove()
		}

		const removeMetaAttributes = (meta: any) => {
			if (!meta) {
				return
			}

			const title = document.querySelector('meta[name="title"]')
			title?.remove()

			const description = document.querySelector('meta[name="description"]')
			description?.remove()

			/* 			const keywords = document.querySelector('meta[name="keywords"]')
			keywords?.remove() */

			if (meta.metaTags) {
				for (const [property] of Object.entries(meta.metaTags)) {
					if (property.startsWith('og:')) {
						removeMetaTag(property, true)
					}
				}
				removeMetaTag('twitter:title')
				removeMetaTag('twitter:description')
				removeMetaTag('twitter:image')
				removeMetaTag('twitter:card')
			}
		}

		router.afterEach((to, from) => {
			removeMetaAttributes(from.meta)
			setMetaAttributes(to.meta, to)
		})
	},
}
