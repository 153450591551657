import { type ApiRequest, RequestAction, type RequestRoutes } from '@/request/requestRouter'
import disconnectUserMiddleware from '@/request/middlewares/disconnectUser'
import checkNotificationMiddleware from '@/request/middlewares/notification'
import type { IItemReward } from '@/types'

const convertToken: ApiRequest<boolean> = {
	action: RequestAction.ConvertToken,
	endpoint: '{{tier}}/convert/{{upgrade|bool(upgrade,downgrade)}}',
	config: {
		method: 'PUT',
		credentials: 'include',
	},
	afterRequest: (response: Response) => response.status === 200,
}

const consumeTokenXp: ApiRequest<number> = {
	action: RequestAction.ConsumeTokenXp,
	endpoint: '{{tier}}/xp',
	config: {
		method: 'PUT',
		credentials: 'include',
	},
	afterRequest: async (response: Response) => {
		const { xp_gain } = await response.json()

		return xp_gain
	},
}

const consumeToken: ApiRequest<IItemReward> = {
	action: RequestAction.ConsumeToken,
	endpoint: '{{tier}}/reward',
	config: {
		method: 'PUT',
		credentials: 'include',
	},
	afterRequest: async (response: Response) => await response.json(),
}

export default {
	prefix: '/api/token',
	middlewares: [disconnectUserMiddleware, checkNotificationMiddleware],
	queries: [convertToken, consumeTokenXp, consumeToken],
} as RequestRoutes
