import type { ApiRequest, RequestRoutes } from '@/request/requestRouter'
import { RequestAction } from '@/request/requestRouter'
import type { IAchievement } from '@/types'

const getAchievements: ApiRequest<IAchievement[]> = {
	action: RequestAction.GetAchievements,
	endpoint: '',
	config: {
		method: 'GET',
		credentials: 'include',
	},
	afterRequest: async (response: Response) => await response.json(),
}

export default {
	prefix: '/api/achievements',
	middlewares: [],
	queries: [getAchievements],
} as RequestRoutes
