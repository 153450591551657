import { RequestAction } from '@/request/requestRouter'
import type { ApiRequest, RequestRoutes } from '@/request/requestRouter'

const getImageCount: ApiRequest<number> = {
	action: RequestAction.GetImageCount,
	endpoint: 'count',
	config: {
		method: 'GET',
	},
	afterRequest: async (response: Response) => {
		const data = await response.json()
		return data.count
	},
}

export default {
	prefix: '/api',
	middlewares: [],
	queries: [getImageCount],
} as RequestRoutes
