import setCloudflareTokenMiddleware from '@/request/middlewares/setCloudflareToken'
import { RequestAction } from '@/request/requestRouter'
import type { ApiRequest, RequestRoutes } from '@/request/requestRouter'
import checkNotificationMiddleware from '@/request/middlewares/notification'
import type { IImage, IPage } from '@/types'

const consumeEvent: ApiRequest<IPage<IImage>> = {
	action: RequestAction.ConsumeEvent,
	endpoint: '{{uuid}}',
	config: {
		method: 'POST',
	},
	afterRequest: async (response: Response) => await response.json(),
}

const getEvent: ApiRequest = {
	action: RequestAction.GetEvent,
	endpoint: '',
	config: {
		method: 'GET',
	},
}

const getEventTokenReward: ApiRequest = {
	action: RequestAction.GetEventTokenReward,
	endpoint: '{{eventUuid}}/{{imageUuid}}',
	config: {
		method: 'POST',
	},
}

const getVisitorEvent: ApiRequest = {
	action: RequestAction.GetVisitorEvent,
	endpoint: 'visitor',
	config: {
		method: 'GET',
	},
}

export default {
	prefix: '/api/event',
	middlewares: [checkNotificationMiddleware, setCloudflareTokenMiddleware],
	queries: [consumeEvent, getEvent, getVisitorEvent, getEventTokenReward],
} as RequestRoutes
