import { RequestAction } from '@/request/requestRouter'
import type { ApiRequest, RequestRoutes } from '@/request/requestRouter'
import type { IFilter, IGroupScene, IScene } from '@/types'

const getB2Token: ApiRequest<string> = {
	action: RequestAction.GetB2Token,
	endpoint: 'b2_token',
	config: {
		method: 'GET',
	},
	afterRequest: async (response: Response) => {
		return (await response.json()).token
	},
}

const getScenesConfig: ApiRequest<IScene[]> = {
	action: RequestAction.GetScenesConfig,
	endpoint: 'scenes/{{render}}',
	config: {
		method: 'GET',
	},
	afterRequest: async (response: Response) => {
		return await response.json()
	},
}

const getFiltersConfig: ApiRequest<IFilter[]> = {
	action: RequestAction.GetFiltersConfig,
	endpoint: '{{render}}/filters',
	config: {
		method: 'GET',
	},
	afterRequest: async (response: Response) => {
		return await response.json()
	},
}

const getSceneFiltersConfig: ApiRequest<IGroupScene[]> = {
	action: RequestAction.GetSceneFiltersConfig,
	endpoint: 'scenes/{{render}}/filters',
	config: {
		method: 'GET',
	},
	afterRequest: async (response: Response) => {
		return await response.json() as IGroupScene[]
	},
}

const setCloudflareToken: ApiRequest<unknown> = {
	action: RequestAction.SetCloudflareToken,
	endpoint: 'token',
	config: {
		method: 'GET',
	},
	afterRequest: async (response: Response) => {
		const { token } = await response.json()

		if (navigator.serviceWorker.controller) {
			navigator.serviceWorker.controller.postMessage({
				type: 'SET_AUTH_TOKEN',
				token,
			})
		}
	},
}

export default {
	prefix: '/api/config',
	middlewares: [],
	queries: [getB2Token, getScenesConfig, getFiltersConfig, getSceneFiltersConfig, setCloudflareToken],
} as RequestRoutes
