import type { IAchievementNotification, IAppEventNotification, IDailyNotification, ILevelUpNotification, INotification, INotificationXpGain, IReportNotification } from '@/types/notification'
import { ENotificationType } from '@/types/notification'

export function isStreakNotification(notification: INotification): notification is IDailyNotification {
	return notification.type === ENotificationType.Daily
		&& notification.data !== undefined
		&& 'streak' in notification.data
}

export function isReportNotification(notification: INotification): notification is IReportNotification {
	return notification.type === ENotificationType.Report
		&& notification.data !== undefined
		&& 'count' in notification.data
}

export function isAppNotification(notification: INotification): notification is INotification {
	return notification.type === ENotificationType.App
}

export function isAppEventNotification(notification: INotification): notification is IAppEventNotification {
	return notification.type === ENotificationType.AppEvent
}

export function isAppErrorNotification(notification: INotification): notification is INotification {
	return notification.type === ENotificationType.AppError
}

export function isLevelUpNotification(notification: INotification): notification is ILevelUpNotification {
	return notification.type === ENotificationType.LevelUp
}

export function isXpGainNotification(notification: INotification): notification is INotificationXpGain {
	return 'xp_gain' in notification && typeof notification.xp_gain === 'number' && notification.xp_gain > 0
}

export function isRewardNotification(notification: INotification): notification is IAchievementNotification {
	return notification.type === ENotificationType.Achievement
		&& 'item_reward_tier' in notification
		&& typeof notification.item_reward_tier === 'string'
		&& notification.item_reward_tier.length > 0
}
