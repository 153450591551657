<script setup lang="ts">
import { computed, defineAsyncComponent, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ICardConfig } from '@/types'
import { useCheckoutPremiumStore } from '@/stores/CheckoutPremiumStore'

const PremiumCard = defineAsyncComponent(() => import('@/components/premium/PremiumCard.vue'))
const Faq = defineAsyncComponent(() => import('@/components/faq/Faq.vue'))

const checkoutPremiumStore = useCheckoutPremiumStore()
const { t } = useI18n()

onMounted(async () => {
	await checkoutPremiumStore.queryTiers()
})

const cardConfig = computed<ICardConfig>(() => {
	return {
		headerTitle: t('premium.title'),
		subHeaderTitle: t('premium.slogan'),
		features: [
			/* t('premium.features.remove_all_ads'), */
			/* t('premium.features.advanced_favorites'), */
			t('premium.features.unlimited_favorites'),
			'Unlimited bandwith',
			/* 			t('premium.features.all_filters_unlocked'),
			t('premium.features.all_scenes_unlocked'), */
			t('premium.features.save_custom_gallery'),
			'Create custom galleries',
			t('premium.features.download_images'),
			/* 'Seed Explorer', */
		],
	}
})
</script>

<template>
	<div class="w-full flex justify-center items-center absolute top-[90px] flex-col">
		<div class="w-full flex flex-wrap justify-center mb-8">
			<h1 class="p-0 m-0 text-3xl sm:text-4xl flex justify-center w-full font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-yellow-100 to-orange-600 pb-2 text-center">
				{{ t('premium.page_title') }}
			</h1>
			<div class="text-gray-400 font-medium text-xs sm:text-sm flex justify-center items-center gap-2 w-full">
				{{ t('premium.page_sub_title') }}
			</div>
		</div>
		<div class="flex w-full justify-center">
			<PremiumCard
				:header-title="cardConfig.headerTitle"
				:sub-header-title="cardConfig.subHeaderTitle"
				:features="cardConfig.features"
			/>
		</div>
		<Divider class="w-10/12 md:w-8/12 mt-16" />
		<Faq />
	</div>
</template>

<style scoped lang="scss">
#paypal-button-container {
	background-color: var(--gray-800);
	margin: 0;
	padding: 0;
}
</style>
