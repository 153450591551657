<script setup lang="ts">
import { defineAsyncComponent, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { onBeforeRouteLeave } from 'vue-router'
import { useFavoriteStore } from '@/stores/favoriteStore'
import { useSettingStore } from '@/stores/settingStore'
import { useAuthStore } from '@/stores/authStore'
import { EImageGalleryType } from '@/types'
import { useAutoScroll } from '@/composables/useAutoScroll'

const Image = defineAsyncComponent(() => import('@/components/Image.vue'))
const ZoomedImage = defineAsyncComponent(() => import('@/components/ZoomedImage.vue'))

const { stop: stopAutoScroll } = useAutoScroll()

const { t } = useI18n()
const authStore = useAuthStore()
const settingStore = useSettingStore()
const imageStore = useFavoriteStore()
const gallery = ref()
const uuid = ref<string>('')

const { galleryType, imageFullScreen, favoriteScroll, restoreScroll } = storeToRefs(settingStore)
const { toDelete, images, index: imageIndex } = storeToRefs(imageStore)
const { user } = storeToRefs(authStore)

galleryType.value = EImageGalleryType.Favorite

function zoomImage(index: number) {
	imageStore.setZoomedImage(index)
	imageFullScreen.value = true
}

function saveScroll(): void {
	favoriteScroll.value = window.scrollY
}

restoreScroll.value = () => {
	setTimeout(() => {
		if (gallery.value instanceof HTMLElement && gallery.value.scrollHeight >= favoriteScroll.value) {
			scrollTo({ top: favoriteScroll.value })
		}
	}, 20)
}

watch(
	() => imageFullScreen.value,
	(newVal) => {
		if (newVal) {
			uuid.value = images.value[imageIndex.value].uuid
			saveScroll()
		}
	},
)

function popstate() {
	imageFullScreen.value = false
	restoreScroll.value()
}

window.addEventListener('popstate', popstate)

onMounted(async () => {
	await nextTick()
	if (!images.value.length) {
		await imageStore.loadMoreImages()
	}
	restoreScroll.value()
})

onBeforeUnmount(() => {
	window.removeEventListener('popstate', popstate)
	saveScroll()
	toDelete.value = []
})

onBeforeRouteLeave(async () => {
	if (!imageFullScreen.value) {
		stopAutoScroll()
	}
})
</script>

<template>
	<div class="mt-[50px] mb-[5px] w-full flex justify-center">
		<div v-if="images.length" ref="gallery" class="w-[100%] flex flex-wrap justify-center relative gap-1">
			<template v-for="(image, index) in images" :key="image.uuid">
				<Image :interactable="true" :data="images[index]" :index="image.uuid" @on-image-zoom="zoomImage(index)" />
			</template>
			<div v-delayed-intersect="imageStore.loadMoreImages" class="w-full h-[10px] absolute bottom-[250px] -z-[1]" />
		</div>
		<div v-else class="flex flex-col">
			<div class="px-4 rounded-md flex flex-col">
				<div class="flex items-center gap-2 sm:gap-5">
					<div class="flex flex-col">
						<h4 class="text-h5 sm:text-h4">
							<span class="text-purple-400">></span> {{ t('favorites.empty.title') }}
						</h4>
						<p class="m-0 pl-1 font-semibold text-sm md:text-base">
							{{ t('favorites.empty.paragraph1') }}
						</p>

						<p class="m-0 pl-1 mt-5 font-semibold text-sm md:text-base">
							{{ t('favorites.empty.paragraph2') }}
						</p>
						<p
							class="m-0 mt-8 sm:mt-6 pl-1 font-medium h-[18px] flex items-center gap-1 text-sm md:text-base"
						>
							{{ t('favorites.empty.paragraph3') }}
						</p>
					</div>
				</div>
			</div>
			<div class="flex justify-center items-end w-full">
				<router-link to="/" class="w-11/12 sm:w-6/12 mt-[3rem]">
					<Button outlined :label="t('favorites.empty.button')" class="w-full" />
				</router-link>
			</div>
		</div>
	</div>
	<ZoomedImage v-if="imageFullScreen" :uuid="uuid" previous-route="/favorites" />
</template>
