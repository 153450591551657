<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { defineAsyncComponent, nextTick, onBeforeUnmount, onMounted, onUnmounted, ref, watch } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import { useGalleryStore } from '@/stores/galleryStore.ts'
import { useSettingStore } from '@/stores/settingStore'
import { useAuthStore } from '@/stores/authStore'
import { EImageGalleryType } from '@/types'
import { useFilters } from '@/composables/useFilters'
import { useScrollDirectionEvent } from '@/composables/useScrollDirectionEvent'
import { useResizeListener } from '@/composables/useResizeListener'
import { useAutoScroll } from '@/composables/useAutoScroll'

const ZoomedImage = defineAsyncComponent(() => import('@/components/ZoomedImage.vue'))
const Image = defineAsyncComponent(() => import('@/components/Image.vue'))
const FiltersPanel = defineAsyncComponent(() => import('@/components/filters/FiltersPanel.vue'))
const SpinnerHeart = defineAsyncComponent(() => import('@/components/SpinnerHeart.vue'))

const { stop: stopAutoScroll, setTarget } = useAutoScroll()
const settingStore = useSettingStore()
const imageStore = useGalleryStore()
const { disable } = useScrollDirectionEvent()
const { open: openedFilters } = useFilters()
const { computedScreenWidth } = useResizeListener()

const authStore = useAuthStore()

const intersectEnabled = ref<boolean>(false)
const gallery = ref<HTMLElement>()
const uuid = ref<string>('')

const { galleryType, imageFullScreen, galleryScroll, restoreScroll } = storeToRefs(settingStore)
const { user } = storeToRefs(authStore)
const { images, index: imageIndex } = storeToRefs(imageStore)

galleryType.value = EImageGalleryType.Gallery

function zoomImage(index: number) {
	imageStore.setZoomedImage(index)
	imageFullScreen.value = true
}

function saveScroll(): void {
	if (gallery.value instanceof HTMLElement) {
		galleryScroll.value = openedFilters.value ? gallery.value.scrollTop : window.scrollY
	}
}

restoreScroll.value = () => {
	setTimeout(() => {
		if (gallery.value instanceof HTMLElement) {
			(openedFilters.value ? gallery.value : window).scrollTo({ top: galleryScroll.value })
			setTarget(openedFilters.value ? gallery.value : window)
			intersectEnabled.value = true
		}
	}, 20)
}

watch(
	() => imageFullScreen.value,
	(newVal) => {
		if (newVal) {
			uuid.value = images.value[imageIndex.value].uuid
			saveScroll()
		}
	},
)

function popstate() {
	imageFullScreen.value = false
	restoreScroll.value()
}

window.addEventListener('popstate', popstate)

onBeforeUnmount(() => {
	window.removeEventListener('popstate', popstate)
	saveScroll()
})

onMounted(async () => {
	await nextTick()
	restoreScroll.value()
})

watch(() => openedFilters.value, async () => {
	if (computedScreenWidth.value >= 768) {
		disable.value = true
		const windowScroll = window.scrollY
		const containerScroll = gallery.value instanceof HTMLElement ? gallery.value.scrollTop : 0
		await nextTick()

		if (gallery.value instanceof HTMLElement) {
			if (windowScroll) {
				gallery.value.scrollTo({ top: windowScroll })
			}
			else {
				window.scrollTo({ top: containerScroll })
			}
		}
		setTimeout(() => {
			disable.value = false
		}, 0)
	}
})

onBeforeRouteLeave(async () => {
	if (!imageFullScreen.value) {
		stopAutoScroll()
	}
})

watch(() => openedFilters.value, async () => {
	if (openedFilters.value) {
		setTarget(gallery.value as HTMLElement)
	}
	else {
		setTarget(window)
	}
})
</script>

<template>
	<SpinnerHeart v-if="!imageStore.allImages.length" class="!fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`" />
	<div class="mt-[50px] mb-[5px] w-full flex justify-center h-[calc(100vh-60px)]">
		<div ref="gallery" style="-webkit-overflow-scrolling: touch;" class="w-full flex flex-wrap justify-center relative gap-1" :class="computedScreenWidth >= 768 && openedFilters ? 'overflow-hidden overflow-y-auto' : ''">
			<template v-for="(image, index) in imageStore.allImages" :key="image.uuid">
				<Image :interactable="true" :data="imageStore.allImages[index]" :index="image.uuid" @on-image-zoom="zoomImage(index)" />
			</template>
			<div v-if="intersectEnabled" v-delayed-intersect="imageStore.loadMoreImages" class="w-full h-[10px] mt-[-250px] -z-[1]" />
		</div>
		<FiltersPanel />
	</div>
	<ZoomedImage v-if="imageFullScreen" :uuid="uuid" previous-route="/" />
</template>
