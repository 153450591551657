import type { IItemRewardTier, IPage } from '@/types'

export type ImageReactionType = 'good' | 'neutral' | 'bad' | null

export interface ImageReaction {
	good_react_cnt: number
	neutral_react_cnt: number
	bad_react_cnt: number
	user_react: ImageReactionType
}

export interface IImage extends ImageReaction {
	id: number
	seed: string
	uuid: string
	scene_id: number
	is_favorite: boolean
	premium: boolean
	filters: Record<string, number>
	rank: IItemRewardTier
	alt?: string
	token?: boolean
}

export interface IImageGallery extends IPage<IImage> {}

export enum EImageGalleryType {
	Favorite = 'gallery/favorite',
	Gallery = 'gallery',
	Event = 'Event',
}
