import { RequestAction } from '@/request/requestRouter'
import type { ApiRequest, RequestRoutes } from '@/request/requestRouter'
import disconnectUserMiddleware from '@/request/middlewares/disconnectUser'
import type { IPlayer } from '@/types'
import checkNotificationMiddleware from '@/request/middlewares/notification'

const getUserPlayer: ApiRequest<IPlayer | null> = {
	action: RequestAction.GetUserPlayer,
	endpoint: 'me',
	config: {
		method: 'GET',
		credentials: 'include',
	},
	afterRequest: async (response: Response) => await response.json(),
}

const updateAvatar: ApiRequest = {
	action: RequestAction.UpdateAvatar,
	endpoint: 'avatar',
	config: {
		method: 'PUT',
		credentials: 'include',
	},
}

const updateTitle: ApiRequest = {
	action: RequestAction.UpdateTitle,
	endpoint: 'title/{{achievementId}}',
	config: {
		method: 'PUT',
		credentials: 'include',
	},
}

export default {
	prefix: '/api/player',
	middlewares: [disconnectUserMiddleware, checkNotificationMiddleware],
	queries: [getUserPlayer, updateAvatar, updateTitle],
} as RequestRoutes
