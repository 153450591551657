import FapFapError from '@/errors/FapFapError'
import { RequestAction } from '@/request/requestRouter'
import type { ApiRequest, RequestRoutes } from '@/request/requestRouter'
import type { IImage } from '@/types'

const getImageInfo: ApiRequest<IImage | FapFapError> = {
	action: RequestAction.GetImageInfo,
	endpoint: '{{uuid}}/info',
	config: {
		method: 'GET',
		credentials: 'include',
	},
	afterRequest: async (response: Response) => {
		const data = await response.json()
		if (!response.ok) {
			throw FapFapError.auto(data.detail.code)
		}
		return data as IImage
	},
}

export default {
	prefix: '/api/image',
	middlewares: [],
	queries: [getImageInfo],
} as RequestRoutes
