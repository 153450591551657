import { type ApiRequest, RequestAction, type RequestConfig, type RequestRoutes } from '@/request/requestRouter'
import type { IImageGallery } from '@/types'
import checkNotificationMiddleware from '@/request/middlewares/notification'
import setCloudflareTokenMiddleware from '@/request/middlewares/setCloudflareToken'

const getGallery: ApiRequest<IImageGallery> = {
	action: RequestAction.GetGallery,
	endpoint: '{{route}}?page={{page}}&size={{size}}',
	config: {
		method: 'POST',
	},
	beforeRequest: (config: RequestConfig, env: Record<string, any>): RequestConfig => {
		const headers = env.headers instanceof Headers
			? Object.fromEntries(env.headers.entries())
			: env.headers

		config.headers = { ...config.headers, ...headers }

		if (env.abortController) {
			config.signal = env.abortController.signal
		}
		if (!config.body) {
			config.body = {}
		}

		return config
	},
	afterRequest: async (response: Response) => await response.json(),
}

export default {
	prefix: '/api',
	middlewares: [checkNotificationMiddleware, setCloudflareTokenMiddleware],
	queries: [getGallery],
} as RequestRoutes
