import { storeToRefs } from 'pinia'
import { useNotificationStore } from '@/stores/notificationStore'
import request from '@/request'
import { RequestAction } from '@/request/requestRouter'

export default async function checkNotificationMiddleware(response: Response): Promise<boolean> {
	if (response.ok && response.headers.get('x-notification')) {
		const notifContainer = await request?.exec(RequestAction.GetNotifications, {
			routeParams: {
				page: 1,
				size: 10,
				scopes: [
					'count',
				],
			},
		})
		const store = useNotificationStore()
		const { countNewNotifs, countNewRewards } = storeToRefs(store)

		countNewNotifs.value = notifContainer.newNotifs ?? 0
		countNewRewards.value = notifContainer.newRewards ?? 0

		for (let i = 0; i < notifContainer.notifs.length; i++) {
			const notif = notifContainer.notifs[i]

			notif.new = true
			store.push(notif.type, notif, notif.new)
		}
	}
	return true
}
