import UAParser from 'ua-parser-js'

export default class DeviceFingerprint {
	private static getGPUInfo(): string {
		const canvas = document.createElement('canvas')
		let gpuInfo = ['default-gpu', 'default-vendor', 'default-shading-lang', 'default-version', 'default-max-texture', 'default-max-combined-texture', 'default-max-vertex-texture', 'default-max-fragment-uniform', 'default-max-vertex-uniform']

		try {
			const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
			if (gl && gl instanceof WebGLRenderingContext) {
				gpuInfo = [
					gl.getParameter(gl.RENDERER) || 'default-renderer',
					gl.getParameter(gl.VENDOR) || 'default-vendor',
					gl.getParameter(gl.SHADING_LANGUAGE_VERSION) || 'default-shading-lang',
					gl.getParameter(gl.VERSION) || 'default-version',
					gl.getParameter(gl.MAX_TEXTURE_SIZE) || 'default-max-texture-size',
					gl.getParameter(gl.MAX_COMBINED_TEXTURE_IMAGE_UNITS) || 'default-max-combined-texture',
					gl.getParameter(gl.MAX_VERTEX_TEXTURE_IMAGE_UNITS) || 'default-max-vertex-texture',
					gl.getParameter(gl.MAX_FRAGMENT_UNIFORM_VECTORS) || 'default-max-fragment-uniform',
					gl.getParameter(gl.MAX_VERTEX_UNIFORM_VECTORS) || 'default-max-vertex-uniform',
				]
			}
		}
		catch (error) {
			console.warn('Failed to retrieve WebGL info, using default values.', error)
		}

		return gpuInfo.join('-')
	}

	public static async create(): Promise<string> {
		try {
			const userAgent: string = navigator.userAgent || 'default-user-agent'
			const uaParser = new UAParser(userAgent)
			const uaParsed = uaParser.getResult()

			// Fallback values for UA data
			const slimUserAgent = `${uaParsed.browser.name || 'default-browser'}-${uaParsed.cpu.architecture || 'default-cpu'}-${uaParsed.engine.name || 'default-engine'}-${uaParsed.os.name || 'default-os'}`

			// Screen info with default values
			const pixelDepth = screen.pixelDepth || 24 // Default to 24 if unavailable
			const colorDepth = screen.colorDepth || 24 // Default to 24 if unavailable
			const timezoneOffset = new Date().getTimezoneOffset() || 0 // Default to 0 if unavailable
			const language: string = navigator.language || 'default-language' // Default to 'default-language'
			const gpuInfo = DeviceFingerprint.getGPUInfo()
			const cpuThreads: number = navigator.hardwareConcurrency || 1 // Fallback to 1 thread if unavailable

			// Build the data to hash
			const fingerprintData = `${slimUserAgent}-${gpuInfo}-${language}-${cpuThreads}-${pixelDepth}-${colorDepth}-${timezoneOffset}`

			// Hash the data using SHA-256
			const encoded = new TextEncoder().encode(fingerprintData)
			const hashBuffer = await crypto.subtle.digest('SHA-256', encoded)

			// Convert hash buffer to hexadecimal string
			return Array.from(new Uint8Array(hashBuffer))
				.map(byte => byte.toString(16).padStart(2, '0'))
				.join('')
		}
		catch (error: unknown) {
			console.error('Error generating device fingerprint:', error)

			let errorMessage = 'unknown-error'

			if (error instanceof Error) {
				errorMessage = error.message.length > 50 ? `${error.message.slice(0, 50)}...` : error.message
			}

			return `default-device-id-${errorMessage}`
		}
	}
}
