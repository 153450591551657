import type { ApiRequest, RequestRoutes } from '@/request/requestRouter'
import { RequestAction } from '@/request/requestRouter'
import type { IItemRewardContainer } from '@/types'
import checkNotificationMiddleware from '@/request/middlewares/notification'

const getRewards: ApiRequest<IItemRewardContainer> = {
	action: RequestAction.GetRewards,
	endpoint: '',
	config: {
		method: 'GET',
		credentials: 'include',
	},
	afterRequest: async (response: Response) => await response.json(),
}

export default {
	prefix: '/api/items',
	middlewares: [checkNotificationMiddleware],
	queries: [getRewards],
} as RequestRoutes
