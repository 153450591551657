import { createLucideIcon } from 'lucide-vue-next'

const FapDexIcon = createLucideIcon('FapDexIcon', [
	['rect', { x: '2', y: '2', width: '20', height: '20', rx: '2' }],
	['rect', { x: '5', y: '5', width: '14', height: '8', rx: '2' }],
	['rect', { x: '5', y: '16', width: '7', height: '3', rx: '1' }],
	['rect', { x: '15', y: '16', width: '4', height: '3', rx: '1' }],
])

const TierSIcon = createLucideIcon('TierSIcon', [
	['polygon', { points: '12 2, 21 8, 21 16, 12 22, 3 16, 3 8' }],
	['line', { 'x1': '9', 'y1': '9', 'x2': '15', 'y2': '9', 'stroke': 'currentColor', 'stroke-width': '2', 'stroke-linecap': 'round' }],
	['line', { 'x1': '9', 'y1': '9', 'x2': '9', 'y2': '12', 'stroke': 'currentColor', 'stroke-width': '2', 'stroke-linecap': 'round' }],
	['line', { 'x1': '9', 'y1': '12', 'x2': '15', 'y2': '12', 'stroke': 'currentColor', 'stroke-width': '2', 'stroke-linecap': 'round' }],
	['line', { 'x1': '15', 'y1': '12', 'x2': '15', 'y2': '15', 'stroke': 'currentColor', 'stroke-width': '2', 'stroke-linecap': 'round' }],
	['line', { 'x1': '9', 'y1': '15', 'x2': '15', 'y2': '15', 'stroke': 'currentColor', 'stroke-width': '2', 'stroke-linecap': 'round' }],
])

const TierAIcon = createLucideIcon('TierAIcon', [
	['polygon', { points: '12 2, 21 8, 21 16, 12 22, 3 16, 3 8' }],
	['line', { 'x1': '9', 'y1': '15', 'x2': '12', 'y2': '9', 'stroke': 'currentColor', 'stroke-width': '2', 'stroke-linecap': 'round' }],
	['line', { 'x1': '15', 'y1': '15', 'x2': '12', 'y2': '9', 'stroke': 'currentColor', 'stroke-width': '2', 'stroke-linecap': 'round' }],
	['line', { 'x1': '10', 'y1': '13', 'x2': '14', 'y2': '13', 'stroke': 'currentColor', 'stroke-width': '2', 'stroke-linecap': 'round' }],
])

const TierBIcon = createLucideIcon('TierBIcon', [
	['polygon', { points: '12 2, 21 8, 21 16, 12 22, 3 16, 3 8' }],
	['line', { 'x1': '10', 'y1': '9', 'x2': '10', 'y2': '15', 'stroke': 'currentColor', 'stroke-width': '2', 'stroke-linecap': 'round' }],
	['line', { 'x1': '10', 'y1': '9', 'x2': '14', 'y2': '9', 'stroke': 'currentColor', 'stroke-width': '2', 'stroke-linecap': 'round' }],
	['line', { 'x1': '10', 'y1': '12', 'x2': '14', 'y2': '12', 'stroke': 'currentColor', 'stroke-width': '2', 'stroke-linecap': 'round' }],
	['line', { 'x1': '10', 'y1': '15', 'x2': '14', 'y2': '15', 'stroke': 'currentColor', 'stroke-width': '2', 'stroke-linecap': 'round' }],
	['line', { 'x1': '14', 'y1': '9', 'x2': '14', 'y2': '12', 'stroke': 'currentColor', 'stroke-width': '2', 'stroke-linecap': 'round' }],
	['line', { 'x1': '14', 'y1': '12', 'x2': '14', 'y2': '15', 'stroke': 'currentColor', 'stroke-width': '2', 'stroke-linecap': 'round' }],
])

const TierCIcon = createLucideIcon('TierCIcon', [
	['polygon', { points: '12 2, 21 8, 21 16, 12 22, 3 16, 3 8' }],
	['line', { 'x1': '14', 'y1': '9', 'x2': '10', 'y2': '9', 'stroke': 'currentColor', 'stroke-width': '2', 'stroke-linecap': 'round' }],
	['line', { 'x1': '10', 'y1': '9', 'x2': '10', 'y2': '15', 'stroke': 'currentColor', 'stroke-width': '2', 'stroke-linecap': 'round' }],
	['line', { 'x1': '10', 'y1': '15', 'x2': '14', 'y2': '15', 'stroke': 'currentColor', 'stroke-width': '2', 'stroke-linecap': 'round' }],
])

const LootBagIcon = createLucideIcon('LootBagIcon', [
	['path', {
		'd': 'M 16.59375 17.332031 L 21.832031 17.332031 C 21.394531 11.527344 18.894531 7.921875 15.6875 5.785156 L 17.148438 2.601562 C 17.234375 2.394531 17.214844 2.15625 17.09375 1.96875 C 16.972656 1.78125 16.765625 1.667969 16.539062 1.667969 L 7.398438 1.667969 C 7.175781 1.667969 6.96875 1.78125 6.84375 1.96875 C 6.722656 2.15625 6.703125 2.394531 6.792969 2.601562 L 7.59375 4.332031 L 14.339844 4.332031 L 13.738281 5.667969 L 12.507812 5.667969 C 12.882812 6.679688 13.160156 7.726562 13.332031 8.792969 C 13.445312 9.453125 13.515625 10.121094 13.546875 10.792969 L 12.492188 11.535156 C 12.496094 10.671875 12.425781 9.808594 12.28125 8.960938 C 12.097656 7.828125 11.777344 6.722656 11.332031 5.667969 L 10.667969 5.667969 C 10.050781 6.445312 9.5 7.269531 9.011719 8.132812 C 8.621094 8.875 8.289062 9.644531 8.019531 10.441406 L 7.105469 9.832031 C 7.375 9.066406 7.699219 8.320312 8.085938 7.605469 C 8.476562 6.9375 8.902344 6.289062 9.367188 5.667969 L 8.199219 5.667969 L 8.253906 5.78125 C 4.71875 8.132812 2.035156 12.265625 2.035156 19.167969 C 2.015625 19.460938 2.113281 19.746094 2.308594 19.96875 C 2.503906 20.1875 2.78125 20.316406 3.074219 20.332031 L 8.40625 20.332031 C 7.941406 19.847656 7.789062 19.140625 8.011719 18.503906 C 8.238281 17.867188 8.804688 17.414062 9.472656 17.332031 C 9.3125 17.0625 9.230469 16.753906 9.226562 16.441406 C 9.226562 15.445312 10.03125 14.640625 11.027344 14.640625 L 15.027344 14.640625 C 16.019531 14.640625 16.828125 15.445312 16.828125 16.441406 C 16.832031 16.753906 16.75 17.0625 16.59375 17.332031 Z M 16.59375 17.332031',
		'fill': 'none',
		'stroke': 'currentColor',
		'stroke-width': '2',
		'stroke-linecap': 'round',
		'stroke-linejoin': 'round',
	}],
	['path', {
		'd': 'M 14.398438 19 C 14.398438 18.632812 14.101562 18.332031 13.734375 18.332031 L 9.734375 18.332031 C 9.363281 18.332031 9.066406 18.632812 9.066406 19 C 9.066406 19.367188 9.363281 19.667969 9.734375 19.667969 L 13.734375 19.667969 C 14.101562 19.667969 14.398438 19.367188 14.398438 19 Z M 14.398438 19',
		'fill': 'none',
		'stroke': 'currentColor',
		'stroke-width': '2',
		'stroke-linecap': 'round',
		'stroke-linejoin': 'round',
	}],
	['path', {
		'd': 'M 15.027344 15.667969 L 11.027344 15.667969 C 10.660156 15.667969 10.359375 15.964844 10.359375 16.332031 C 10.359375 16.703125 10.660156 17 11.027344 17 L 15.027344 17 C 15.394531 17 15.691406 16.703125 15.691406 16.332031 C 15.691406 15.964844 15.394531 15.667969 15.027344 15.667969 Z M 15.027344 15.667969',
		'fill': 'none',
		'stroke': 'currentColor',
		'stroke-width': '2',
		'stroke-linecap': 'round',
		'stroke-linejoin': 'round',
	}],
	['path', {
		'd': 'M 14.667969 21 L 10.667969 21 C 10.296875 21 10 21.296875 10 21.667969 C 10 22.035156 10.296875 22.332031 10.667969 22.332031 L 14.667969 22.332031 C 15.035156 22.332031 15.332031 22.035156 15.332031 21.667969 C 15.332031 21.296875 15.035156 21 14.667969 21 Z M 14.667969 21',
		'fill': 'none',
		'stroke': 'currentColor',
		'stroke-width': '2',
		'stroke-linecap': 'round',
		'stroke-linejoin': 'round',
	}],
	['path', {
		'd': 'M 21.800781 21 L 17.132812 21 C 16.765625 21 16.464844 21.296875 16.464844 21.667969 C 16.464844 22.035156 16.765625 22.332031 17.132812 22.332031 L 21.800781 22.332031 C 22.167969 22.332031 22.464844 22.035156 22.464844 21.667969 C 22.464844 21.296875 22.167969 21 21.800781 21 Z M 21.800781 21',
		'fill': 'none',
		'stroke': 'currentColor',
		'stroke-width': '2',
		'stroke-linecap': 'round',
		'stroke-linejoin': 'round',
	}],
	['path', {
		'd': 'M 22.464844 18.332031 L 17.800781 18.332031 C 17.433594 18.332031 17.132812 18.632812 17.132812 19 C 17.132812 19.367188 17.433594 19.667969 17.800781 19.667969 L 22.464844 19.667969 C 22.835938 19.667969 23.132812 19.367188 23.132812 19 C 23.132812 18.632812 22.835938 18.332031 22.464844 18.332031 Z M 22.464844 18.332031',
		'fill': 'none',
		'stroke': 'currentColor',
		'stroke-width': '2',
		'stroke-linecap': 'round',
		'stroke-linejoin': 'round',
	}],
])

export { FapDexIcon, TierSIcon, TierAIcon, TierBIcon, TierCIcon, LootBagIcon }
